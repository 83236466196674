import Link from 'components/links/Link';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';


export default function MarkdownSyntaxPage() {
  const appContext = useAppContext();

  const renderAttachment = (filename, title) => (
    <a href={appContext.apiUrl(`/download/file/attachment/${filename}`)}>{title || filename}</a>
  );

  const preStyle = {whiteSpace: 'pre'};

  return (
    <ReadableWidthTemplate title="Markdown Syntax">
      <section id="headings">
        <h2>Headings</h2>
        <p>These headings <strong>should</strong> be used for titles/subtitles in the text - <strong>do not</strong> use bold or italic text for this purpose.</p>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {/*
              The level of headers are decreased a level (e.g. h1 -> h2) in the markdown parser
              */}
            <tr>
              <td><code># Level 1 Heading</code></td>
              <td><h2 id="level-1-heading">Level 1 Heading</h2></td>
            </tr>
            <tr>
              <td><code>## Level 2 Heading</code></td>
              <td><h3>Level 2 Heading</h3></td>
            </tr>
            <tr>
              <td><code>### Level 3 Heading</code></td>
              <td><h4>Level 3 Heading</h4></td>
            </tr>
            <tr>
              <td><code>#### Level 4 Heading</code></td>
              <td><h5>Level 4 Heading</h5></td>
            </tr>
          </tbody>
        </table>
      </section>

      <section id="text">
        <h2>Text Formatting</h2>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><code>_italic_</code> (single underscore)</td>
              <td><em>italic</em></td>
            </tr>
            <tr>
              <td><code>__bold__</code> (double underscore)</td>
              <td><strong>bold</strong></td>
            </tr>
            <tr>
              <td><code>___bold and italic___</code> (triple underscore)</td>
              <td><em><strong>bold and italic</strong></em></td>
            </tr>
            <tr>
              <td><code>Text^superscript^</code></td>
              <td>Text<sup>superscript</sup></td>
            </tr>
            <tr>
              <td><code>Text~subscript~</code></td>
              <td>Text<sub>subscript</sub></td>
            </tr>
            <tr>
              <td><code>{'{{monospaced}}'}</code></td>
              <td><span className="markdown-monospace">monospaced</span></td>
            </tr>
            <tr>
              <td>Symbols: <code>&amp;alpha;</code>, <code>&amp;beta;</code>, <code>&amp;delta;</code>,
                <code>&amp;rho;</code>, <code>&amp;sigma;</code>, <code>&amp;theta;</code><br />
                Using the format <code>&amp;nnnn;</code> where &quot;nnnn&quot; is <Link href="https://en.wikipedia.org/wiki/List_of_XML_and_HTML_character_entity_references#Character_entity_references_in_HTML">name of the symbol</Link> you want to use (e.g. alpha, beta, gamma)
              </td>
              <td>Symbols: &alpha; &beta; &delta; &rho; &sigma; &theta;</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section id="linking">
        <h2>Linking</h2>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>outside link:<br /><code>[http://www.ncbi.nlm.nih.gov/pubmed]</code></td>
              <td>outside link: <Link href="http://www.ncbi.nlm.nih.gov/pubmed">http://www.ncbi.nlm.nih.gov/pubmed</Link></td>
            </tr>
            <tr>
              <td>aliased outside link: <code>[PubMed](http://www.ncbi.nlm.nih.gov/pubmed)</code></td>
              <td>aliased outside link: <Link href="http://www.ncbi.nlm.nih.gov/pubmed">PubMed</Link></td>
            </tr>
            <tr>
              <td>basic link to another page: <code>[/page/wiki]</code></td>
              <td>basic link to another page: <a href="/page/wiki">/page/wiki</a></td>
            </tr>
            <tr>
              <td>aliased link to another page: <code>[About Us](/page/about)</code></td>
              <td>aliased link to another page: <a href="/page/about">About Us</a></td>
            </tr>
            <tr>
              <td>basic link to an attachment: <code>[^warfarin_CPIC.pdf]</code></td>
              <td>basic link to an attachment: {renderAttachment('warfarin_CPIC.pdf')}</td>
            </tr>
            <tr>
              <td>aliased link to an attachment:<br /><code>[Warfarin Dosing](^warfarin_CPIC.pdf)</code></td>
              <td>aliased link to an attachment: {renderAttachment('warfarin_CPIC.pdf', 'Warfarin Dosing')}</td>
            </tr>
            <tr>
              <td>download submission: <code>[^PS203195]</code></td>
              <td>download submission: <a href="https://api.pgkb.org/v1/download/submission/PS203195">PS203195</a></td>
            </tr>
            <tr>
              <td>link to a pmid: <code>[PMID:12345]</code></td>
              <td>link to a pmid: [Article:<a href="/pmid/12345">12345</a>]</td>
            </tr>
            <tr>
              <td>
                link back to the &quot;Level 1 Heading&quot; on this page:<br />
                <code>[Some link text](#level-1-heading)</code><br />
                <small style={{display: 'block'}} className="mt-3">
                  Note: the link is a hash (<code>#</code>) followed by the text of the heading, all lowercase,
                  with spaces replaced by hyphens (<code>-</code>).
                </small>
              </td>
              <td>
                link back to the &quot;Level 1 Heading&quot; on this page:<br />
                <a href="#level-1-heading">Some link text</a>
              </td>
            </tr>
            <tr>
              <td>link to multiple pmids: <code>[PMID:12345,23456,34567]</code></td>
              <td>
                link to multiple pmids: [Articles:<a href="/pmid/12345">12345</a>, <a href="/pmid/23456">23456</a>,
                <a href="/pmid/34567">34567</a>]
              </td>
            </tr>
            <tr>
              <td>link to a gene by ID: <code>[gene:PA267]</code><br />
                (e.g. <code>[chemical:PA449845]</code>, <code>[disease:PA446522]</code>, <code>[pathway:PA164713469]</code>,
                <code>[haplotype:PA165818750]</code> or <code>[vip:PA267])</code>)
              </td>
              <td>link to a gene by ID: <a href="/gene/PA267">ABCB1</a><br />
                (e.g. <a href="/chemical/PA449845">halothane</a>, <a href="/disease/PA446522">Neuroectodermal Tumors</a>,
                <a href="/pathway/PA2029">Irinotecan Pathway, Pharmacodynamics</a>,
                <a href="/haplotype/PA165818750">CYP2B6*1</a> or <a href="/vip/PA267">ABCB1 VIP</a>)
              </td>
            </tr>
            <tr>
              <td>link to a gene by symbol: <code>[gene:ABCB1]</code><br />
                (e.g. <code>[chemical:warfarin]</code>, <code>[disease:Neoplasms]</code>, <code>[haplotype:CYP2D6*1]</code>,
                <code>[vip:ABCB1]</code>)
              </td>
              <td>link to a gene by symbol: <a href="/gene/PA267">ABCB1</a><br />
                (e.g. <a href="/chemical/PA451906">warfarin</a>, <a href="/disease/PA445062">Neoplasms</a>,
                <a href="/haplotype/PA165816576">CYP2D6*1</a>, <a href="/vip/PA267">ABCB1 VIP</a>)
              </td>
            </tr>
            <tr>
              <td>aliased link to a gene by ID: <code>[this gene](gene:PA267)</code><br />
                (e.g. <code>[this chemical](chemical:PA451906)</code>), <code>[this disease](disease:PA446522)</code>,
                <code>[this pathway](pathway:PA164713469)</code>, <code>[this haplotype](haplotype:PA165818750)</code>,
                <code>[this vip](vip:PA267)</code>)
              </td>
              <td>aliased link to a gene by ID: <a href="/gene/PA267">ABCB1</a><br />
                (e.g. <a href="/chemical/PA451906">this chemical</a>, <a href="/disease/PA446522">this disease</a>,
                <a href="/haplotype/PA165818750">this haplotype</a>, <a href="/vip/PA267">this vip</a>)
              </td>
            </tr>
            <tr>
              <td>aliased link to a gene by symbol: <code>[this gene](gene:ABCB1)</code><br />
                (e.g. <code>[this chemical](chemical:warfarin)</code>, <code>[this disease](disease: Neoplasms)</code>,
                <code>[this haplotype](haplotype:CYP2B6*1)</code>, <code>[this vip](vip:ABCB1)</code>)
              </td>
              <td>aliased link to a gene by symbol: <a href="/gene/PA267">this gene</a><br />
                (e.g. <a href="/chemical/PA451906">this chemical</a>, <a href="/disease/PA445062">this disease</a>,
                <a href="/haplotype/PA165818750">this haplotype</a>, <a href="/vip/PA267">this vip</a>)
              </td>
            </tr>
            <tr>
              <td>link to RSID: <code>[variant:rs1234]</code></td>
              <td>link to RSID: <a href="/rsid/rs1234">rs1234</a></td>
            </tr>
          </tbody>
        </table>
      </section>

      <section id="lists">
        <h2>Lists</h2>
        <p>
          Nest lists by inserting <strong>4 spaces</strong> at the start of the line. To nest a list inside another nested
          list, just add another 4 spaces to the start of the line.
        </p>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Unordered list:</p>
                <code style={preStyle}>{'- bullet point 1\n- bullet point 2\n    - sub-bullet point' +
                  ' 2a\n        - sub-sub-bullet point 2a-1'}
                </code>
              </td>
              <td>
                <p>Unordered list:</p>
                <ul>
                  <li>bullet point 1</li>
                  <li>bullet point 2
                    <ul>
                      <li>sub-bullet point 2a
                        <ul>
                          <li>sub-sub-bullet point 2a-1</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>Ordered list:</p>
                <code style={preStyle}>{'1. bullet point 1\n    2. sub-bullet point 1\n1. bullet point' +
                  ' 2\n1. bullet point 3'}
                </code>
              </td>
              <td>
                <p>Ordered list:</p>
                <ol>
                  <li>bullet point 1
                    <ol>
                      <li>sub-bullet point 1</li>
                    </ol>
                  </li>
                  <li>bullet point 2</li>
                  <li>bullet point 3</li>
                </ol>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          <strong>Note:</strong> the numbers used for ordered list are unimportant. You can use any number, even the same for
          all, and in any order but there <strong>must</strong> be a number at the start of the line.
        </p>

        <p>Whitespace (e.g. blank lines) between items in lists is important, as it will treat each list item separated by these lines as a new paragraph. This will alter the formatting of the resulting list, as seen in the example below.</p>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>List with blank lines:</p>
                <code style={preStyle}>{'- bullet point 1\n    - sub-bullet point 1a\n\n- bullet point 2\n' +
                  '    - sub-bullet point 2a\n        - sub-sub-bullet point 2a-1\n\n- bullet' +
                  ' point 3'}
                </code>
              </td>
              <td>
                <p>List with blank lines:</p>
                <ul>
                  <li><p>bullet point 1</p>
                    <ul>
                      <li>sub-bullet point 1a</li>
                    </ul>
                  </li>
                  <li><p>bullet point 2</p>
                    <ul>
                      <li>sub-bullet point 2a
                        <ul>
                          <li>sub-sub-bullet point 2a-1</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li><p>bullet point 3</p></li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>List without blank lines:</p>
                <code style={preStyle}>{'- bullet point 1\n    - sub-bullet point 1\n- bullet point' +
                  ' 2\n- bullet point 3'}
                </code>
              </td>
              <td>
                <p>List without blank lines:</p>
                <ul>
                  <li>bullet point 1
                    <ul>
                      <li>sub-bullet point 1</li>
                    </ul>
                  </li>
                  <li>bullet point 2</li>
                  <li>bullet point 3</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <p>Regular and multi-paragraph lists can be intermixed between top-level &quot;parent&quot; and nested &quot;child&quot; lists.</p>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Regular lists:</p>
                <code style={preStyle}>{'- bullet point 1\n    - sub-bullet point 1a\n- bullet point 2\n' +
                  '    - sub-bullet point 2a\n        - sub-sub-bullet point 2a-1\n        -' +
                  ' sub-sub-bullet point 2a-2\n    - sub-bullet point 2b\n- bullet point 3\n' +
                  '    - sub-bullet point 3a\n    - sub-bullet point 3b\n- bullet point 4'}
                </code>
              </td>
              <td>
                <p>Regular lists:</p>
                <ul>
                  <li>bullet point 1
                    <ul>
                      <li>sub-bullet point 1a</li>
                    </ul>
                  </li>
                  <li>bullet point 2
                    <ul>
                      <li>sub-bullet point 2a
                        <ul>
                          <li>sub-sub-bullet point 2a-1</li>
                          <li>sub-sub-bullet point 2a-2</li>
                        </ul>
                      </li>
                      <li>sub-bullet point 2b</li>
                    </ul>
                  </li>
                  <li>bullet point 3
                    <ul>
                      <li>sub-bullet point 3a</li>
                      <li>sub-bullet point 3b</li>
                    </ul>
                  </li>
                  <li>bullet point 4</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>Mixed regular and multi-paragraph lists:</p>
                <code style={preStyle}>{'- bullet point 1\n    - sub-bullet point 1a\n- bullet point 2\n' +
                  '    - sub-bullet point 2a\n        - sub-sub-bullet point 2a-1\n\n        -' +
                  ' sub-sub-bullet point 2a-2\n\n    - sub-bullet point 2b\n- bullet point 3\n' +
                  '    - sub-bullet point 3a\n    - sub-bullet point 3b\n\n- bullet point 4'}
                </code>
              </td>
              <td>
                <p>Mixed regular and multi-paragraph lists:</p>
                <ul>
                  <li><p>bullet point 1</p>
                    <ul>
                      <li>sub-bullet point 1a</li>
                    </ul>
                  </li>
                  <li><p>bullet point 2</p>
                    <ul>
                      <li><p>sub-bullet point 2a</p>
                        <ul>
                          <li><p>sub-sub-bullet point 2a-1</p></li>
                          <li><p>sub-sub-bullet point 2a-2</p></li>
                        </ul>
                      </li>
                      <li><p>sub-bullet point 2b</p></li>
                    </ul>
                  </li>
                  <li><p>bullet point 3</p>
                    <ul>
                      <li>sub-bullet point 3a</li>
                      <li>sub-bullet point 3b</li>
                    </ul>
                  </li>
                  <li>bullet point 4</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Note:</strong> adding even just <strong>one</strong> blank line between any list items (as between bullet points 3 and 4 above) will result in the whole list being made multi-paragraph. To avoid this, do not place blank lines between any list items.
        </p>

        <p>If you wish to create two separate consecutive lists in markdown, separate the lists using a non-breaking space character (<code>{'&nbsp;'}</code>).</p>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Consecutive lists:</p>
                <code style={preStyle}>{'- bullet point 1-1\n    - sub-bullet point 1-1a\n- bullet point 1-2\n' +
                  '    - sub-bullet point 1-2a\n    - sub-bullet point 1-2b\n\n&nbsp;\n\n-' +
                  ' bullet point 2-1\n    - sub-bullet point 2-1a\n    - sub-bullet point' +
                  ' 2-1b\n- bullet point 2-2\n- bullet point 2-3'}
                </code>
              </td>
              <td>
                <p>Regular lists:</p>
                <ul>
                  <li>bullet point 1-1
                    <ul>
                      <li>sub-bullet point 1-1a</li>
                    </ul>
                  </li>
                  <li>bullet point 1-2
                    <ul>
                      <li>sub-bullet point 1-2a</li>
                      <li>sub-bullet point 1-2b</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>bullet point 2-1
                    <ul>
                      <li>sub-bullet point 2-1a</li>
                      <li>sub-bullet point 2-1b</li>
                    </ul>
                  </li>
                  <li>bullet point 2-2</li>
                  <li>bullet point 2-3</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          For multi-column lists, the following classes are available:
        </p>
        <ul>
          <li>list-2-col</li>
          <li>list-3-col</li>
          <li>list-4-col</li>
          <li>list-5-col</li>
          <li>list-6-col</li>
        </ul>

        <h6>Markdown</h6>
        <p>
          <code style={preStyle}>{'<div class="list-2-col">\n\n- gene 1\n- gene 2\n- gene 3\n- gene 4\n- gene 5\n\n</div>'}</code>
        </p>

        <h6>Result</h6>
        <div className="list-2-col">
          <ul>
            <li>gene 1</li>
            <li>gene 2</li>
            <li>gene 3</li>
            <li>gene 4</li>
            <li>gene 5</li>
          </ul>
        </div>

        <h6>Markdown</h6>
        <p>
          <code style={preStyle}>{'<div class="list-5-col">\n\n- gene 1\n- gene 2 with long name\n- gene 3\n- gene 4\n- gene 5\n- gene 6\n- gene 7\n- gene 8\n- gene 9\n\n</div>'}</code>
        </p>

        <h6>Result</h6>
        <div className="list-5-col">
          <ul>
            <li>gene 1</li>
            <li>gene 2 with long name</li>
            <li>gene 3</li>
            <li>gene 4</li>
            <li>gene 5</li>
            <li>gene 6</li>
            <li>gene 7</li>
            <li>gene 8</li>
            <li>gene 9</li>
          </ul>
        </div>

        <p>
          Note: You must leave a blank line after the opening <code>&lt;div&gt;</code> tag and before the
          closing <code>&lt;/div&gt;</code> tag.
        </p>

      </section>

      <section id="tables">
        <h2>Tables</h2>
        <p>Note: tables will always span the entire page.</p>
        <ol>
          <li>There <strong>must</strong> be at least three hyphens in each column of the header row (see below).</li>
          <li>There must be a blank line above and below the table.</li>
        </ol>

        <h6>Markdown</h6>
        <p>
          <code style={preStyle}>{'| header 1 | header 2 |\n| -------- | -------- |\n| colA1 | colA2 |\n| colB1 | colB2 |'}</code>
        </p>

        <h6>Result</h6>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>header 1</th>
              <th>header 2</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>colA1</td>
              <td>colA2</td>
            </tr>
            <tr>
              <td>colB1</td>
              <td>colB2</td>
            </tr>
          </tbody>
        </table>
        <p>
          Markdown enforces that tables have headers so use the following construct to display a
          table without headers (i.e. blank column header names).
        </p>
        <h6>Markdown</h6>
        <p>
          <code style={preStyle}>{'| | |\n| --- | --- |\n| colA1 | colA2 |\n| colB1 | colB2 |'}</code>
        </p>

        <h6>Result</h6>
        <table className="table unstriped">
          <tbody>
            <tr>
              <td>colA1</td>
              <td>colA2</td>
            </tr>
            <tr>
              <td>colB1</td>
              <td>colB2</td>
            </tr>
          </tbody>
        </table>
        <p>
          {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
          To add a newline within a table place a double forward-slash <code>//</code>, with spaces before and after, where you wish the line to break.
        </p>
        <h6>Markdown</h6>
        <p>
          <code style={preStyle}>{'| Col 1 | Col 2 |\n| --- | --- |\n| Some text // On newline | c |' +
            '\n| d | e |'}
          </code>
        </p>

        <h6>Result</h6>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Col 1</th>
              <th>Col 2</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Some text<br />On newline</td>
              <td>c</td>
            </tr>
            <tr>
              <td>d</td>
              <td>e</td>
            </tr>
          </tbody>
        </table>

        <p>
          Tables can also be made in Google Sheets and placed in the text using:
          <code>[sheet: https://docs.google.com/spreadsheets/d/1jVw4PjutO30_XRfql-6ulUR9Ex3bpQVuEBDQKp-nNlA/pubhtml?]</code>
        </p>
        <p>
          This tag also takes an optional <code>height</code> parameter (in pixels) which changes the height of the displayed
          table. For example:
          <code>[sheet: https://docs.google.com/spreadsheets/d/1jVw4PjutO30_XRfql-6ulUR9Ex3bpQVuEBDQKp-nNlA/pubhtml?height=500]</code>
        </p>
        <p>
          The link is found by clicking
          <code>File &gt; Publish to the web... &gt; Embed tab &gt; Choose the Sheet to Publish &gt; Publish</code>. Then copy the link
          in <code>src</code> attribute up to the <code>?</code>
        </p>
      </section>

      <section id="images">
        <h2>Images</h2>
        <p>Basic format: <code>![alternative text](url)</code></p>
        <p>
          The <code>alternative text</code> is text shown if the image cannot be viewed for any reason.
          This is important for accessibility (e.g. for screen readers) and{' '}
          <strong>should be set for all images</strong>.
        </p>
        <p>Advanced format: <code>![alternative text|width=x|height=y|centered](url)</code></p>
        <p>
          The remaining parameters <code>width</code>, <code>height</code>, <code>centered</code> are optional but
          provide more control over the image, and can be used independently or together.  To maintain the aspect
          ratio of the image, it is best to only use either <code>width</code> or <code>height</code>.
        </p>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Images from external link:</p>
                <code>![CPIC logo](https://s3.pgkb.org/attachment/cpic-logo.png)</code>
              </td>
              <td><img src="https://s3.pgkb.org/attachment/cpic-logo.png" alt="CPIC logo" /></td>
            </tr>
            <tr>
              <td>
                <p>Images from attachment:</p>
                <code>![CPIC logo](^cpic-logo.png)</code>
              </td>
              <td><img src="https://s3.pgkb.org/attachment/cpic-logo.png" alt="CPIC logo" /></td>
            </tr>
            <tr>
              <td>
                <p>Modify width:</p>
                <code>![CPIC logo|width=200](^cpic-logo.png)</code>
              </td>
              <td><img src="https://s3.pgkb.org/attachment/cpic-logo.png" alt="CPIC logo" width="200" /></td>
            </tr>
            <tr>
              <td>
                <p>Modify height:</p>
                <code>![CPIC logo|height=100](^cpic-logo.png)</code>
              </td>
              <td><img src="https://s3.pgkb.org/attachment/cpic-logo.png" alt="CPIC logo" height="100" /></td>
            </tr>
            <tr>
              <td>
                <p>Modify width and height:</p>
                <code>![CPIC logo|height=50|width=200](^cpic-logo.png)</code>
              </td>
              <td><img src="https://s3.pgkb.org/attachment/cpic-logo.png" alt="CPIC logo" height="50" width="200" /></td>
            </tr>
            <tr>
              <td>
                <p>Center image:</p>
                <code>![CPIC logo|centered](^cpic-logo.png)</code>
              </td>
              <td><img src="https://s3.pgkb.org/attachment/cpic-logo.png" alt="CPIC logo" className="img--centered" /></td>
            </tr>
          </tbody>
        </table>
      </section>

      <section id="blockquotes">
        <h2>Blockquotes</h2>
        <p>
          Create a block quote by starting the paragraph with <code>&gt;</code>. This is meant to denote text that was copied verbatim from an outside source (e.g. literature or a webpage).
        </p>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><code>This text is before the quote.<br />&gt; This text is in a blockquote and, thus, should come from an outside source.<br />This text is after the quote.</code></td>
              <td><p>This text is before the quote.</p><blockquote className="blockquote">This text is in a blockquote and, thus, should come from an outside source.</blockquote><p>This text is after the quote.</p></td>
            </tr>
            <tr>
              <td>
                <code style={preStyle}>{'This text is before the quote.\n' +
                  '> This blockquote has multiple paragraphs.\n' +
                  '>\n' +
                  '> Paragraph 1\n' +
                  '>\n' +
                  '> Paragraph 2\n' +
                  '>\n' +
                  '> <cite>by Geronimo</cite>\n' +
                  'This text is after the quote. Notice that we can use the\n' +
                  '<code>cite</code> tag to indicate the source of the blockquote.'}
                </code>
              </td>
              <td>
                <p>This text is before the quote.</p>
                <blockquote className="blockquote">
                  <p>This blockquote has multiple paragraphs.</p>
                  <p>Paragraph 1</p>
                  <p>Paragraph 2</p>
                  <p><cite>by Geronimo</cite></p>
                </blockquote>
                <p>
                  This text is after the quote. Notice that we can use the <code>cite</code> tag
                  to indicate the source of the blockquote.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section id="linebreak">
        <h2>Line break</h2>
        <p>
          To do a line break without starting a whole new paragraph, put <strong>two (2) spaces at the end of the first line</strong> then just hit return.
        </p>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>This is the first line <i style={{color: 'gray'}}>space</i> <i style={{color: 'gray'}}>space</i></code><br />
                <code>This is the second</code>
              </td>
              <td>
                This is the first line<br />This is the second
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section id="escaping">
        <h2>Escaping</h2>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><code>This is an escaped \[outside link]</code></td>
              <td>This is an escaped [outside link]</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section id="dna">
        <h2>DNA sequences</h2>
        <p>
          For DNA sequences within text (i.e. in a paragraph) use the <code>{'{dna}'}</code> tag - this places the text in the
          tags in a monospace font.
        </p>
        <p>
          For standalone DNA sequences, use three backticks <code>`</code> followed by <code>dna</code>, the DNA
          sequence/sequences on a new line, followed by three more backticks.
        </p>
        <p>
          Standalone sequences can be aligned using spaces.
        </p>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>This <code>{'{dna}ACTG{dna}'}</code> is a DNA sequence</td>
              <td>This <span className="markdown-language-dna">ACTG</span> is a DNA sequence</td>
            </tr>
            <tr>
              <td><code>{'```dna\nACTGTGCA\nACGTAGTC\n```'}</code>
              </td>
              <td><pre className="markdown-language-dna">{'ACTGTGCA\nACGTAGTC'}</pre>
              </td>
            </tr>
          </tbody>
        </table>

        <h4>Color</h4>
        <p>
          Text within DNA tags and blocks can be colored by enclosing it in color tags e.g. <code>{'{color:X}text{color}'}</code>
          where X represents a color and can be 1 through 5.
          The colors are labelled as numbers because they may be changed at any time (particularly
          when we change to the new site) so be aware that the color you choose may not be
          permanent.
        </p>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>This <code>{'{dna}{color:1}AGG{color}{dna}'}</code> is a DNA sequence</td>
              <td>This <span className="markdown-language-dna"><span className="markdown-language-dna__color1">AGG</span></span> is a DNA sequence</td>
            </tr>
            <tr>
              <td>Here is a DNA sequence:<br /><code style={preStyle}>{'```dna\n{color:4}TCA{color}\n```\n'}</code>And some text about it</td>
              <td>Here is a DNA sequence:<pre className="markdown-language-dna"><span className="markdown-language-dna__color4">TCA</span></pre>And some text about it</td>
            </tr>
          </tbody>
        </table>

        <h5>Color codes</h5>
        <ul>
          <li className="markdown-language-dna__color1">Color Code 1</li>
          <li className="markdown-language-dna__color2">Color Code 2</li>
          <li className="markdown-language-dna__color3">Color Code 3</li>
          <li className="markdown-language-dna__color4">Color Code 4</li>
          <li className="markdown-language-dna__color5">Color Code 5</li>
        </ul>

        <h4>Notes</h4>
        <ul>
          <li>Only bold and color tags can be used to style the text.</li>
          <li>Spaces are not needed before and after the underscores when bolding text (this only applies in DNA sequences)</li>
          <li>If you need to put an underscore as part of the sequence, put a backslash <code>/</code> in front</li>
        </ul>
      </section>

      <section id="tags">
        <h2>Tags</h2>
        <p>
          For tags, use the <code>{'{tag:xxx}'}</code> where <code>xxx</code> is the tag label.
        </p>
        <p>
          Valid labels are:
        </p>
        <table className="table unstriped">
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                For clinical annotations:<br />
                <code>{'{tag:Level 1A}'}</code><br />
                <code>{'{tag:Level 1B}'}</code><br />
                <code>{'{tag:Level 2A}'}</code><br />
                <code>{'{tag:Level 2B}'}</code><br />
                <code>{'{tag:Level 3}'}</code><br />
                <code>{'{tag:Level 4}'}</code>
              </td>
              <td>
                For clinical annotations:<br />
                <span className="tag tag--sm caLevelTag caLevelTag--1"><span className="tag__label">Level 1A</span></span><br />
                <span className="tag tag--sm caLevelTag caLevelTag--1"><span className="tag__label">Level 1B</span></span><br />
                <span className="tag tag--sm caLevelTag caLevelTag--2"><span className="tag__label">Level 2A</span></span><br />
                <span className="tag tag--sm caLevelTag caLevelTag--2"><span className="tag__label">Level 2B</span></span><br />
                <span className="tag tag--sm caLevelTag caLevelTag--3"><span className="tag__label">Level 3</span></span><br />
                <span className="tag tag--sm caLevelTag caLevelTag--4"><span className="tag__label">Level 4</span></span>
              </td>
            </tr>
            <tr>
              <td>
                For label annotations:<br />
                <code>{'{tag:Testing Required}'}</code><br />
                <code>{'{tag:Testing Recommended}'}</code><br />
                <code>{'{tag:Actionable PGx}'}</code><br />
                <code>{'{tag:Informative PGx}'}</code><br />
                <code>{'{tag:No Clinical PGx}'}</code>
              </td>
              <td>
                For label annotations:<br />
                <span className="tag tag--sm laLevelTag laLevelTag--1"><span className="tag__label">Testing Required</span></span><br />
                <span className="tag tag--sm laLevelTag laLevelTag--2"><span className="tag__label">Testing Recommended</span></span><br />
                <span className="tag tag--sm laLevelTag laLevelTag--3"><span className="tag__label">Actionable PGx</span></span><br />
                <span className="tag tag--sm laLevelTag laLevelTag--4"><span className="tag__label">Informative PGx</span></span><br />
                <span className="tag tag--sm laLevelTag laLevelTag--5"><span className="tag__label">No Clinical PGx</span></span>
              </td>
            </tr>

            <tr>
              <td><code>{'{tag:Alternate Drug}'}</code></td>
              <td><span className="tag tag--sm alternateDrugTag"><span className="tag__label">Alternate Drug</span></span></td>
            </tr>
            <tr>
              <td><code>{'{tag:AMP Tier 1}'}</code></td>
              <td><span className="tag tag--sm "><span className="tag__label">AMP Tier 1</span></span></td>
            </tr>
            <tr>
              <td><code>{'{tag:BPCA Drug}'}</code></td>
              <td><span className="tag tag--sm "><span className="tag__label">BPCA Drug</span></span></td>
            </tr>
            <tr>
              <td><code>{'{tag:Cancer Genome}'}</code></td>
              <td><span className="tag tag--sm cancerGenomeTag"><span className="tag__label">Cancer Genome</span></span></td>
            </tr>
            <tr>
              <td><code>{'{tag:Criteria Not Met}'}</code></td>
              <td><span className="tag tag--sm "><span className="tag__label">Criteria Not Met</span></span></td>
            </tr>
            <tr>
              <td><code>{'{tag:Dosing Info}'}</code></td>
              <td><span className="tag tag--sm dosingInfoTag"><span className="tag__label">Dosing Info</span></span></td>
            </tr>
            <tr>
              <td>
                <code>{'{tag:FDA Biomarker}'}</code><br />
                <code>{'{tag:Ex-FDA Biomarker}'}</code>
              </td>
              <td>
                <span className="tag tag--sm fdaBiomarkerTag"><span className="tag__label">FDA Biomarker</span></span><br />
                <span className="tag tag--sm fdaBiomarkerTag"><span className="tag__label">Ex-FDA Biomarker</span></span>
              </td>
            </tr>
            <tr>
              <td><code>{'{tag:Indication}'}</code></td>
              <td><span className="tag tag--sm indicationTag"><span className="tag__label">Indication</span></span></td>
            </tr>
            <tr>
              <td><code>{'{tag:Limited Evidence}'}</code></td>
              <td><span className="tag tag--sm "><span className="tag__label">Limited Evidence</span></span></td>
            </tr>
            <tr>
              <td><code>{'{tag:Other Guidance}'}</code></td>
              <td><span className="tag tag--sm otherGuidanceTag"><span className="tag__label">Other Guidance</span></span></td>
            </tr>
            <tr>
              <td><code>{'{tag:Pediatric}'}</code></td>
              <td><span className="tag tag--sm "><span className="tag__label">Pediatric</span></span></td>
            </tr>
            <tr>
              <td><code>{'{tag:Prescribing Info}'}</code></td>
              <td><span className="tag tag--sm prescribingInfoTag"><span className="tag__label">Prescribing Info</span></span></td>
            </tr>
            <tr>
              <td><code>{'{tag:Rare Variant}'}</code></td>
              <td><span className="tag tag--sm "><span className="tag__label">Rare Variant</span></span></td>
            </tr>
            <tr>
              <td><code>{'{tag:Retired}'}</code></td>
              <td><span className="tag tag--sm tag--retired"><span className="tag__label">Retired</span></span></td>
            </tr>
            <tr>
              <td><code>{'{tag:Testing Info}'}</code></td>
              <td><span className="tag tag--sm "><span className="tag__label">Testing Info</span></span></td>
            </tr>
            <tr>
              <td><code>{'{tag:VIP Tier 1}'}</code></td>
              <td><span className="tag tag--sm vipTierTag"><span className="tag__label">VIP Tier 1</span></span></td>
            </tr>
          </tbody>
        </table>
      </section>
    </ReadableWidthTemplate>
  );
}
