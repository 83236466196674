import PropTypes from 'prop-types';

import {renderMetadata} from 'components/Literature/Citation';
import Loading from 'components/Loading';
import SideNav from 'components/SideNav';
import {getMenuItems, getTabComponent} from 'components/SideNav/utils';
import ErrorMessage from 'components/errors/ErrorMessage';
import KyError from 'components/errors/KyError';
import NotFound from 'components/shared/error/not_found';
import FullWidthResourceTemplate from 'components/templates/FullWidthResource';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import usePediatricContext from 'context/Pediatric';
import {useGet} from 'helpers/KyHooks';
import LiteratureOverviewTab from 'pages/Literature/OverviewTab';


const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  section: PropTypes.string,
  newRel: PropTypes.string,
};
/**
 * Renders literature page.
 */
export default function LiteraturePage({id, section = 'overview', newRel}) {
  const appContext = useAppContext();
  const pediatricContext = usePediatricContext();
  const showOverview = !section || section === 'overview';

  const {response, error} = useGet(`site/literature/${id}`, {
    cacheResponse: !appContext.isPreview,
  });

  let name = `Literature ${id}`;
  let subtitle;
  let body = <Loading />;
  let counts = {};
  if (error) {
    if (error.response?.status === 404) {
      return <NotFound />;
    }
    body = <KyError kyError={error} />;

  } else if (response) {
    const {literature, related, relationships, lastUpdated, vipRelationships, rxAnn, ampTesting} = response.data;
    counts = response.data.counts ?? {};
    name = literature.title;
    if (literature.journal) {
      subtitle = (
        <div className="resource-header-subtitle--hasIcon resource-header-subtitle--light">
          {renderMetadata(literature.journal, literature.year, null)}
        </div>
      );
    }

    if (showOverview) {
      body = (
        <LiteratureOverviewTab
          literature={literature}
          related={related}
          relationships={relationships}
          vipRelationships={vipRelationships}
          lastUpdated={lastUpdated}
          newRel={newRel}
          rxAnn={rxAnn}
          ampTesting={ampTesting}
        />
      );
    }
  }
  const Comp = getTabComponent(section, pediatricContext.isPediatricMode);
  if (Comp) {
    body = <Comp id={id} objCls="literature" name={name} />;
  } else if (!showOverview) {
    body = <ErrorMessage message={`Unsupported tab "${section}"`} />;
  }

  const baseUrl = Types.literature.url(id);
  const menuItems = getMenuItems('literature', baseUrl, counts);

  return (
    <FullWidthResourceTemplate id={id} type="literature" name={name} subtitle={subtitle}>
      <SideNav items={menuItems}>
        {body}
      </SideNav>
    </FullWidthResourceTemplate>
  );
}
LiteraturePage.propTypes = propTypes;
