import {map} from 'lodash';

import DrugResultGridAnnotation from 'pages/genotypeResults/DrugResultGridAnnotation';

/**
 * A component that will display all the annotations from a data source (e.g. CPIC, DPWG)
 *
 * @param {object} props - props container
 * @param {object} props.drug - the drug these annotations are for
 * @param {Array<object>} props.source - an object containing annotations that contain recommendations (based on guideline annotations, label annotations, etc)
 * @param {Function} props.onOpenChange - handler for changing open state of child recommendations
 */
export default function DrugResultGridSource({drug, source, onOpenChange}) {
  const {annotations, description} = source;
  return (
    <div className="cell-copy">
      {map(annotations, (annotation) => (
        <DrugResultGridAnnotation
          key={`${drug.id}${annotation.id}`}
          id={annotation.id}
          drug={drug}
          onOpenChange={onOpenChange}
          {...annotation}
        />
      ))}
      {!!description && <p>{description}</p>}
    </div>
  );
}
