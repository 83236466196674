import {filter} from 'lodash';
import {useState} from 'react';

import './index.scss';
import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import KyError from 'components/errors/KyError';
import InfoLink from 'components/links/Info';
import CuratorOnly from 'components/shared/curator_only';
import FullFluidWidthTemplate from 'components/templates/FullFluidWidth';
import useAppContext from 'conf/AppContext';
import {DataSource} from 'conf/enums';
import {useGet} from 'helpers/KyHooks';
import LabelAnnotationsBarChart from 'pages/LabelAnnotations/BarChart';
import LabelAnnotationDrugTable from 'pages/LabelAnnotations/DrugTable';

export default function LabelAnnotationsPage() {
  const appContext = useAppContext();

  const {response, error} = useGet('site/labelsByDrug', {
    cacheResponse: !appContext.isPreview,
  });

  const [pgxLevelFilter, setPgxLevelFilter] = useState(null);
  const [showSwissmedicAndPMDALabels, setShowSwissmedicAndPMDALabels] = useState(false);

  let table = <Loading />;
  let barChart = (
    <div className="laFigure laFigure--loading figure figure--floatRight">
      <Loading />
    </div>
  );
  if (error) {
    table = <KyError kyError={error} />;
  } else if (response) {
    let {data} = response;
    if (!showSwissmedicAndPMDALabels) {
      data = filter(response.data, (drug) =>
        Object.keys(drug.labels).includes(DataSource.displayName['U.S. Food and Drug Administration'] ||
          DataSource.displayName['European Medicines Agency'] ||
          DataSource.displayName['Health Canada/Santé Canada'],
        ));
    }
    table = (
      <LabelAnnotationDrugTable
        data={data}
        updatedFilters={pgxLevelFilter}
        showSwissMedicAndPMDALabels={showSwissmedicAndPMDALabels}
      />
    );
    barChart = (
      <LabelAnnotationsBarChart
        labelAnnotations={data}
        filterChangeFn={setPgxLevelFilter}
        showSwissMedicAndPMDALabels={showSwissmedicAndPMDALabels}
        className="figure--floatRight"
      />
    );
  }

  return (
    <FullFluidWidthTemplate className="labelAnnotationsPage" title="Drug Label Annotations">
      <div className="container mb-3 clearfix">
        {barChart}
        <InlinePage id="drugLabelListingHeader" />
        {toggleSwissmedicAndPMDALabels(setShowSwissmedicAndPMDALabels, showSwissmedicAndPMDALabels)}
      </div>
      {listAllLabelAnnotations()}

      {table}
    </FullFluidWidthTemplate>
  );
}

export function listAllLabelAnnotations() {
  return (
    <CuratorOnly>
      <a href="/edit/labelAnnotations">Curation Dashboard: List All Label Annotations</a>
    </CuratorOnly>
  );
}

function toggleSwissmedicAndPMDALabels(setShowSwissmedicAndPMDALabels, showSwissmedicAndPMDALabels) {
  const changeHandler = () => setShowSwissmedicAndPMDALabels(!showSwissmedicAndPMDALabels);
  return (
    <label htmlFor="toggleSources" className="mt-2">
      <input id="toggleSources" type="checkbox" onChange={changeHandler} />
      <strong>{' '}Show all sources including Swissmedic and PMDA</strong>
      <InfoLink
        href="/page/drugLabelLegend#drug-label-collaborations"
        iconClassName="icon--superscript"
        iconSize="xs"
        newTab={true}
      />
    </label>
  );
}
