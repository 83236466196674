export const BiomarkerStatus = {
  shortName: {
    biomarker: 'On FDA Biomarker List',
    exBiomarker: 'Formerly on FDA Biomarker List',
  },
  displayName: {
    'Formerly on FDA Biomarker List': 'exBiomarker',
    'On FDA Biomarker List': 'biomarker',
  },
};
export const ChemicalType = {
  shortName: {
    drug: 'Drug',
    metabolite: 'Metabolite',
    ion: 'Ion',
    drugClass: 'Drug Class',
    biologicalIntermediate: 'Biological Intermediate',
    smallMolecule: 'Small Molecule',
    prodrug: 'Prodrug',
  },
  displayName: {
    'Biological Intermediate': 'biologicalIntermediate',
    Drug: 'drug',
    'Drug Class': 'drugClass',
    Ion: 'ion',
    Metabolite: 'metabolite',
    Prodrug: 'prodrug',
    'Small Molecule': 'smallMolecule',
  },
};
export const ClinicalAnnotationType = {
  shortName: {
    dosage: 'Dosage',
    efficacy: 'Efficacy',
    toxicity: 'Toxicity',
    pk: 'Metabolism/PK',
    pd: 'PD',
    other: 'Other',
  },
  displayName: {
    Dosage: 'dosage',
    Efficacy: 'efficacy',
    'Metabolism/PK': 'pk',
    Other: 'other',
    PD: 'pd',
    Toxicity: 'toxicity',
  },
};
export const CrossReferenceResource = {
  shortName: {
    dbSnp: 'dbSNP',
    genbank: 'GenBank',
    genbankNucleotide: 'GenBank Nucleotide',
    genbankProtein: 'GenBank Protein',
    entrezGene: 'NCBI Gene',
    omim: 'OMIM',
    pharmgkb: 'PharmGKB',
    pubMed: 'PubMed',
    refSeqRna: 'RefSeq RNA',
    refSeqProtein: 'RefSeq Protein',
    refSeqDna: 'RefSeq DNA',
    ucscGenomeBrowser: 'UCSC Genome Browser',
    url: 'URL',
    uniProtKb: 'UniProtKB',
    ensembl: 'Ensembl',
    genAtlas: 'GenAtlas',
    geneCard: 'GeneCard',
    ndc: 'National Drug Code Directory',
    drugBank: 'DrugBank',
    drugBankMetabolite: 'DrugBank Metabolite',
    pdb: 'PDB',
    ctd: 'Comparative Toxicogenomics Database',
    cas: 'Chemical Abstracts Service',
    modBase: 'ModBase',
    chebi: 'ChEBI',
    keggCompound: 'KEGG Compound',
    keggDrug: 'KEGG Drug',
    keggPathway: 'KEGG Pathway',
    pubchemCompound: 'PubChem Compound',
    pubchemSubstance: 'PubChem Substance',
    pubchemBioAssay: 'PubChem BioAssay',
    HumanCycGene: 'HumanCyc Gene',
    iupharLigand: 'IUPHAR Ligand',
    iupharReceptor: 'IUPHAR Receptor',
    hgnc: 'HGNC',
    reactomeReaction: 'Reactome Reaction',
    dpd: 'Canadian Drug Product Database',
    bindingDb: 'BindingDB',
    chemSpider: 'ChemSpider',
    TTD: 'Therapeutic Targets Database',
    DailyMed: 'FDA Drug Label at DailyMed',
    clinicalTrials: 'ClinicalTrials.gov',
    isrctn: 'International Standard Randomised Controlled Trial',
    geo: 'Gene Expression Omnibus',
    doi: 'DOI',
    pii: 'Publisher Item Identifiers',
    pmc: 'PubMed Central',
    ec: 'Enzyme Commission',
    hcsc: 'HC-SC',
    hmdb: 'HMDB',
    clinvar: 'ClinVar',
    fda: 'FDA Application',
    gtr: 'GTR',
    wikidata: 'Wikidata',
    swiss: 'Swissmedic',
    pharmVar: 'PharmVar',
    lrg: 'LRG',
    pharmVarGene: 'PharmVar Gene',
    chembl: 'ChEMBL',
    unii: 'UNII',
    drugBankSalt: 'DrugBank Salt',
    pdbLigand: 'PDB Ligand',
  },
  displayName: {
    BindingDB: 'bindingDb',
    'Canadian Drug Product Database': 'dpd',
    ChEBI: 'chebi',
    ChEMBL: 'chembl',
    ChemSpider: 'chemSpider',
    'Chemical Abstracts Service': 'cas',
    ClinVar: 'clinvar',
    'ClinicalTrials.gov': 'clinicalTrials',
    'Comparative Toxicogenomics Database': 'ctd',
    DOI: 'doi',
    DrugBank: 'drugBank',
    'DrugBank Metabolite': 'drugBankMetabolite',
    'DrugBank Salt': 'drugBankSalt',
    Ensembl: 'ensembl',
    'Enzyme Commission': 'ec',
    'FDA Application': 'fda',
    'FDA Drug Label at DailyMed': 'DailyMed',
    GTR: 'gtr',
    GenAtlas: 'genAtlas',
    GenBank: 'genbank',
    'GenBank Nucleotide': 'genbankNucleotide',
    'GenBank Protein': 'genbankProtein',
    'Gene Expression Omnibus': 'geo',
    GeneCard: 'geneCard',
    'HC-SC': 'hcsc',
    HGNC: 'hgnc',
    HMDB: 'hmdb',
    'HumanCyc Gene': 'HumanCycGene',
    'IUPHAR Ligand': 'iupharLigand',
    'IUPHAR Receptor': 'iupharReceptor',
    'International Standard Randomised Controlled Trial': 'isrctn',
    'KEGG Compound': 'keggCompound',
    'KEGG Drug': 'keggDrug',
    'KEGG Pathway': 'keggPathway',
    LRG: 'lrg',
    ModBase: 'modBase',
    'NCBI Gene': 'entrezGene',
    'National Drug Code Directory': 'ndc',
    OMIM: 'omim',
    PDB: 'pdb',
    'PDB Ligand': 'pdbLigand',
    PharmGKB: 'pharmgkb',
    PharmVar: 'pharmVar',
    'PharmVar Gene': 'pharmVarGene',
    'PubChem BioAssay': 'pubchemBioAssay',
    'PubChem Compound': 'pubchemCompound',
    'PubChem Substance': 'pubchemSubstance',
    PubMed: 'pubMed',
    'PubMed Central': 'pmc',
    'Publisher Item Identifiers': 'pii',
    'Reactome Reaction': 'reactomeReaction',
    'RefSeq DNA': 'refSeqDna',
    'RefSeq Protein': 'refSeqProtein',
    'RefSeq RNA': 'refSeqRna',
    Swissmedic: 'swiss',
    'Therapeutic Targets Database': 'TTD',
    'UCSC Genome Browser': 'ucscGenomeBrowser',
    UNII: 'unii',
    URL: 'url',
    UniProtKB: 'uniProtKb',
    Wikidata: 'wikidata',
    dbSNP: 'dbSnp',
  },
};
export const CurationState = {
  shortName: {
    notCurated: 'Not Curated',
    notRelevant: 'Not Relevant',
    relevantKeyword: 'Relevant Keyword',
    relevantAssociation: 'Relevant Association',
    relevant: 'Relevant',
    curated: 'Curated',
  },
  displayName: {
    Curated: 'curated',
    'Not Curated': 'notCurated',
    'Not Relevant': 'notRelevant',
    Relevant: 'relevant',
    'Relevant Association': 'relevantAssociation',
    'Relevant Keyword': 'relevantKeyword',
  },
};
export const DataAnnotationType = {
  shortName: {
    absorption: 'Absorption',
    biotransformation: 'Biotransformation',
    foodInteraction: 'Food Interaction',
    indication: 'Indication',
    mechanismOfAction: 'Mechanism of Action',
    pharmacology: 'Pharmacology',
    proteinBinding: 'Protein Binding',
    toxicity: 'Toxicity',
    contraindication: 'Contraindication',
    pgxSummary: 'Pharmacogenetics',
    description: 'Description',
    molWeightAvg: 'Average Molecular Weight',
    molWeightMono: 'Monoisotopic Molecular Weight',
    halflife: 'Half-Life',
    formula: 'Chemical Formula',
    adverseReaction: 'Adverse Reaction',
    product: 'Product',
    structureInfo: 'Structural Information',
    appendix: 'Appendix',
    phenotypeInfo: 'Phenotype Information',
    clearance: 'Clearance',
    routeOfElimination: 'Route of Elimination',
    distribution: 'Volume of Distribution',
    haplotypeOverview: 'Haplotype Overview',
    dpwgAction: 'DPWG Actionability',
    acmgIncFind: 'ACMG Incidental Findings',
    precautions: 'Precautions',
    warnings: 'Warnings',
    boxedWarnings: 'Boxed warnings',
    publicationSet: 'Publication Set',
    SNPIT: 'University of Florida Health SNP-IT',
    rx: 'Rx Study Annotation',
    vip: 'Very Important Pharmacogene',
    alleleNotes: 'Allele Notes',
    pedSummary: 'Pediatric Summary',
    frequencyNotes: 'Frequency Notes',
    pgxTesting: 'PGx Testing',
  },
  displayName: {
    'ACMG Incidental Findings': 'acmgIncFind',
    Absorption: 'absorption',
    'Adverse Reaction': 'adverseReaction',
    'Allele Notes': 'alleleNotes',
    Appendix: 'appendix',
    'Average Molecular Weight': 'molWeightAvg',
    Biotransformation: 'biotransformation',
    'Boxed warnings': 'boxedWarnings',
    'Chemical Formula': 'formula',
    Clearance: 'clearance',
    Contraindication: 'contraindication',
    'DPWG Actionability': 'dpwgAction',
    Description: 'description',
    'Food Interaction': 'foodInteraction',
    'Frequency Notes': 'frequencyNotes',
    'Half-Life': 'halflife',
    'Haplotype Overview': 'haplotypeOverview',
    Indication: 'indication',
    'Mechanism of Action': 'mechanismOfAction',
    'Monoisotopic Molecular Weight': 'molWeightMono',
    'PGx Testing': 'pgxTesting',
    'Pediatric Summary': 'pedSummary',
    Pharmacogenetics: 'pgxSummary',
    Pharmacology: 'pharmacology',
    'Phenotype Information': 'phenotypeInfo',
    Precautions: 'precautions',
    Product: 'product',
    'Protein Binding': 'proteinBinding',
    'Publication Set': 'publicationSet',
    'Route of Elimination': 'routeOfElimination',
    'Rx Study Annotation': 'rx',
    'Structural Information': 'structureInfo',
    Toxicity: 'toxicity',
    'University of Florida Health SNP-IT': 'SNPIT',
    'Very Important Pharmacogene': 'vip',
    'Volume of Distribution': 'distribution',
    Warnings: 'warnings',
  },
};
export const DataSource = {
  shortName: {
    unknown: 'Unknown',
    HGNC: 'Hugo Gene Nomenclature Committee',
    NCBI_Gene: 'NCBI Gene',
    GO: 'Gene Ontology',
    CAS: 'Chemical Abstracts Service',
    OMIM: 'Online Mendelian Inheritance in Man',
    UMLS: 'Unified Medical Language System',
    CTD: 'Comparative Toxicogenomics Database',
    UniProt: 'UniProt',
    GenAtlas: 'GenAtlas',
    Ensembl: 'Ensembl',
    RefSeq: 'NCBI Reference Sequence Database',
    GenBank: 'GenBank',
    Reactome: 'Reactome',
    DrugBank: 'DrugBank',
    dbSnp: 'dbSnp',
    HapMap: 'International HapMap Project',
    FDA: 'U.S. Food and Drug Administration',
    PubChem: 'PubChem',
    ModBase: 'Database of Comparative Protein Structure Models',
    DPD: 'Canadian Drug Product Database',
    ATC: 'Anatomical Therapeutic Chemical Classification',
    PubMed: 'PubMed',
    HumanCyc_Gene: 'HumanCyc Gene',
    SnoMedCT: 'SnoMedCT',
    MeSH: 'Medical Subject Headings',
    PharmGKB: 'PharmGKB',
    IUPHAR: 'International Union of Basic and Clinical Pharmacology',
    BindingDB: 'BindingDB',
    UCSC: 'UCSC Genome Browser',
    GeneCard: 'GeneCard',
    CPIC: 'Clinical Pharmacogenetics Implementation Consortium',
    DPWG: 'Dutch Pharmacogenetics Working Group',
    EMA: 'European Medicines Agency',
    PRO: 'Professional Society',
    RxNorm: 'RxNorm',
    ClinVar: 'ClinVar',
    MedDRA: 'Medical Dictionary for Regulatory Activities',
    'NDF-RT': 'National Drug File - Reference Terminology',
    LOINC: 'LOINC',
    'ClinicalTrials.gov': 'ClinicalTrials.gov',
    HMDB: 'Human Metabolome Database',
    DailyMed: 'DailyMed',
    '1000_Genomes': '1000 Genomes',
    PMDA: 'Pharmaceuticals and Medical Devices Agency, Japan',
    HCSC: 'Health Canada/Santé Canada',
    ChEBI: 'ChEBI',
    CPNDS: 'Canadian Pharmacogenomics Network for Drug Safety',
    fdaPharmClasses: 'FDA Pharm Classes',
    gnomadExome: 'gnomAD Exomes',
    gnomadGenome: 'gnomAD Genomes',
    Swissmedic: 'Swissmedic',
    MONDO: 'Mondo Disease Ontology',
    PharmVar: 'Pharmacogene Variation Consortium',
    PharmGKB_Haplotype: 'PharmGKB Haplotype',
    ALFA: 'Allele Frequency Aggregator',
    RNPGx: 'French National Network of Pharmacogenetics',
    ACR: 'American College of Rheumatology',
    'SEFF/SEOM': 'Spanish Pharmacogenetics and Pharmacogenomics Society and the Spanish Society of Medical Oncology',
    CFF: 'Cystic Fibrosis Foundation',
    AusNZ: 'Australian and New Zealand consensus guidelines',
    ChEMBL: 'ChEMBL',
    KEGG: 'Kyoto Encyclopedia of Genes and Genomes',
    PDB: 'Protein Data Bank',
    AIOM: 'Italian Association of Medical Oncology',
    PMBB: 'Penn Medicine Bio-bank',
    UKBB: 'United Kingdom Bio-bank',
    AoU: 'All of Us',
    AMP: 'Association for Molecular Pathology',
  },
  displayName: {
    '1000 Genomes': '1000_Genomes',
    'All of Us': 'AoU',
    'Allele Frequency Aggregator': 'ALFA',
    'American College of Rheumatology': 'ACR',
    'Anatomical Therapeutic Chemical Classification': 'ATC',
    'Association for Molecular Pathology': 'AMP',
    'Australian and New Zealand consensus guidelines': 'AusNZ',
    BindingDB: 'BindingDB',
    'Canadian Drug Product Database': 'DPD',
    'Canadian Pharmacogenomics Network for Drug Safety': 'CPNDS',
    ChEBI: 'ChEBI',
    ChEMBL: 'ChEMBL',
    'Chemical Abstracts Service': 'CAS',
    ClinVar: 'ClinVar',
    'Clinical Pharmacogenetics Implementation Consortium': 'CPIC',
    'ClinicalTrials.gov': 'ClinicalTrials.gov',
    'Comparative Toxicogenomics Database': 'CTD',
    'Cystic Fibrosis Foundation': 'CFF',
    DailyMed: 'DailyMed',
    'Database of Comparative Protein Structure Models': 'ModBase',
    DrugBank: 'DrugBank',
    'Dutch Pharmacogenetics Working Group': 'DPWG',
    Ensembl: 'Ensembl',
    'European Medicines Agency': 'EMA',
    'FDA Pharm Classes': 'fdaPharmClasses',
    'French National Network of Pharmacogenetics': 'RNPGx',
    GenAtlas: 'GenAtlas',
    GenBank: 'GenBank',
    'Gene Ontology': 'GO',
    GeneCard: 'GeneCard',
    'Health Canada/Santé Canada': 'HCSC',
    'Hugo Gene Nomenclature Committee': 'HGNC',
    'Human Metabolome Database': 'HMDB',
    'HumanCyc Gene': 'HumanCyc_Gene',
    'International HapMap Project': 'HapMap',
    'International Union of Basic and Clinical Pharmacology': 'IUPHAR',
    'Italian Association of Medical Oncology': 'AIOM',
    'Kyoto Encyclopedia of Genes and Genomes': 'KEGG',
    LOINC: 'LOINC',
    'Medical Dictionary for Regulatory Activities': 'MedDRA',
    'Medical Subject Headings': 'MeSH',
    'Mondo Disease Ontology': 'MONDO',
    'NCBI Gene': 'NCBI_Gene',
    'NCBI Reference Sequence Database': 'RefSeq',
    'National Drug File - Reference Terminology': 'NDF-RT',
    'Online Mendelian Inheritance in Man': 'OMIM',
    'Penn Medicine Bio-bank': 'PMBB',
    PharmGKB: 'PharmGKB',
    'PharmGKB Haplotype': 'PharmGKB_Haplotype',
    'Pharmaceuticals and Medical Devices Agency, Japan': 'PMDA',
    'Pharmacogene Variation Consortium': 'PharmVar',
    'Professional Society': 'PRO',
    'Protein Data Bank': 'PDB',
    PubChem: 'PubChem',
    PubMed: 'PubMed',
    Reactome: 'Reactome',
    RxNorm: 'RxNorm',
    SnoMedCT: 'SnoMedCT',
    'Spanish Pharmacogenetics and Pharmacogenomics Society and the Spanish Society of Medical Oncology': 'SEFF/SEOM',
    Swissmedic: 'Swissmedic',
    'U.S. Food and Drug Administration': 'FDA',
    'UCSC Genome Browser': 'UCSC',
    UniProt: 'UniProt',
    'Unified Medical Language System': 'UMLS',
    'United Kingdom Bio-bank': 'UKBB',
    Unknown: 'unknown',
    dbSnp: 'dbSnp',
    'gnomAD Exomes': 'gnomadExome',
    'gnomAD Genomes': 'gnomadGenome',
  },
  acronyms: {
    HGNC: 'HGNC',
    GO: 'GO',
    CAS: 'CAS',
    OMIM: 'OMIM',
    UMLS: 'UMLS',
    CTD: 'CTD',
    RefSeq: 'RefSeq',
    HapMap: 'HapMap',
    FDA: 'FDA',
    ModBase: 'ModBase',
    DPD: 'DPD',
    ATC: 'ATC',
    IUPHAR: 'IUPHAR',
    CPIC: 'CPIC',
    DPWG: 'DPWG',
    EMA: 'EMA',
    MedDRA: 'MedDRA',
    'NDF-RT': 'NDF-RT',
    HMDB: 'HMDB',
    PMDA: 'PMDA',
    HCSC: 'HCSC',
    CPNDS: 'CPNDS',
    MONDO: 'MONDO',
    PharmVar: 'PharmVar',
    ALFA: 'ALFA',
    RNPGx: 'RNPGx',
    ACR: 'ACR',
    'SEFF/SEOM': 'SEFF/SEOM',
    CFF: 'CFF',
    AusNZ: 'AusNZ',
    KEGG: 'KEGG',
    PDB: 'PDB',
    PMBB: 'PMBB',
    UKBB: 'UKBB',
    AoU: 'AoU',
    AMP: 'AMP',
  },
};
export const LinkOutResource = {
  shortName: {
    dbSnp: 'dbSNP',
    GO: 'GO',
    genbank: 'GenBank',
    genbankNucleotide: 'GenBank Nucleotide',
    genbankProtein: 'GenBank Protein',
    MeSH: 'MeSH',
    ATC: 'ATC',
    entrezGene: 'NCBI Gene',
    SnoMedCT: 'SnoMed CT',
    omim: 'OMIM',
    pharmgkb: 'PharmGKB',
    pubMed: 'PubMed',
    refSeqRna: 'RefSeq RNA',
    refSeqProtein: 'RefSeq Protein',
    refSeqDna: 'RefSeq DNA',
    ucscGenomeBrowser: 'UCSC Genome Browser',
    UMLS: 'UMLS',
    MedDRA: 'MedDRA',
    RxNorm: 'RxNorm',
    url: 'URL',
    NDFRT: 'NDF-RT',
    uniProtKb: 'UniProt',
    ensembl: 'Ensembl',
    genAtlas: 'GenAtlas',
    geneCard: 'GeneCard',
    andOr: 'Collection Operator - And/Or',
    yesNo: 'Confirmation Operator - Yes/No',
    drugBlackList: 'Drug Name Black List',
    CL: 'CL',
    cellularLocation: 'Cellular Location',
    pathwayEnzymaticModification: 'Enzymatic Modification',
    pathwayEntities: 'Pathway Entities',
    pathwayProcesses: 'Pathway Processes',
    PvState: 'State Ontology',
    transmembraneTypes: 'Transmembrane Type',
    chemicalRole: 'Chemical Role',
    ndc: 'NDC',
    drugBank: 'DrugBank',
    drugBankMetabolite: 'DrugBank Metabolite',
    pdb: 'PDB',
    levelsOfEvidence: 'Level of Evidence',
    ctd: 'CTD',
    cas: 'CAS',
    modBase: 'ModBase',
    rxChange: 'Prescribing Change',
    SPL: 'SPL Section',
    retirement: 'Retirement Reason',
    geneTestLevel: 'Genetic Testing Level',
    chebi: 'ChEBI',
    keggCompound: 'KEGG Compound',
    keggDrug: 'KEGG Drug',
    keggPathway: 'KEGG Pathway',
    pubchemCompound: 'PubChem Compound',
    pubchemSubstance: 'PubChem Substance',
    pubchemBioAssay: 'PubChem BioAssay',
    guidelineStrength: 'Guideline Strength',
    HumanCycGene: 'HumanCyc Gene',
    iupharLigand: 'IUPHAR Ligand',
    iupharReceptor: 'IUPHAR Receptor',
    hgnc: 'HGNC',
    reactomeReaction: 'Reactome Reaction',
    geneRole: 'Gene Role',
    dpd: 'DPD',
    bindingDb: 'BindingDB',
    chemSpider: 'ChemSpider',
    alleleFunction: 'Allele Function',
    TTD: 'Therapeutic Targets Database',
    DailyMed: 'FDA Drug Label at DailyMed',
    pathwayCategories: 'Pathway Categories',
    guidelineTags: 'Guideline Tags',
    clinicalTrials: 'ClinicalTrials.gov',
    isrctn: 'ISRCTN',
    geo: 'GEO',
    doi: 'DOI',
    pii: 'Publisher Item Identifiers',
    pmc: 'PubMed Central',
    ec: 'Enzyme Commission',
    hcsc: 'HC-SC',
    hmdb: 'HMDB',
    clinvar: 'ClinVar',
    significance: 'Association Significance',
    association: 'Association Type',
    cellCat: 'Cell Type',
    equality: 'Equality Symbols',
    drug_ConnWords1: 'Drug Connecting words 1',
    fa_ConnWords1: 'Functional Assay Connecting words 1',
    metabolizer: 'Metabolizer Option',
    phenoCat: 'Phenotype Categories',
    connWords1: 'Phenotype Connecting words 1',
    connWords2: 'Phenotype or Gene to Drug Connecting Words',
    polarity: 'Polarity',
    populationTerm: 'Population',
    populationType: 'Population Type',
    statAnalysis: 'Statistic Analysis',
    statTest: 'Statistical Test Tags',
    fda: 'FDA Application',
    gtr: 'GTR',
    loinc: 'LOINC',
    wikidata: 'Wikidata',
    swiss: 'Swissmedic',
    pharmVar: 'PharmVar',
    MONDO: 'MONDO',
    lrg: 'LRG',
    pharmVarGene: 'PharmVar Gene',
    pgkbTags: 'PharmGKB Tags',
    pgxPaperTypes: 'PGx Paper Types',
    chembl: 'ChEMBL',
    unii: 'UNII',
    drugBankSalt: 'DrugBank Salt',
    pdbLigand: 'PDB Ligand',
  },
  displayName: {
    ATC: 'ATC',
    'Allele Function': 'alleleFunction',
    'Association Significance': 'significance',
    'Association Type': 'association',
    BindingDB: 'bindingDb',
    CAS: 'cas',
    CL: 'CL',
    CTD: 'ctd',
    'Cell Type': 'cellCat',
    'Cellular Location': 'cellularLocation',
    ChEBI: 'chebi',
    ChEMBL: 'chembl',
    ChemSpider: 'chemSpider',
    'Chemical Role': 'chemicalRole',
    ClinVar: 'clinvar',
    'ClinicalTrials.gov': 'clinicalTrials',
    'Collection Operator - And/Or': 'andOr',
    'Confirmation Operator - Yes/No': 'yesNo',
    DOI: 'doi',
    DPD: 'dpd',
    'Drug Connecting words 1': 'drug_ConnWords1',
    'Drug Name Black List': 'drugBlackList',
    DrugBank: 'drugBank',
    'DrugBank Metabolite': 'drugBankMetabolite',
    'DrugBank Salt': 'drugBankSalt',
    Ensembl: 'ensembl',
    'Enzymatic Modification': 'pathwayEnzymaticModification',
    'Enzyme Commission': 'ec',
    'Equality Symbols': 'equality',
    'FDA Application': 'fda',
    'FDA Drug Label at DailyMed': 'DailyMed',
    'Functional Assay Connecting words 1': 'fa_ConnWords1',
    GEO: 'geo',
    GO: 'GO',
    GTR: 'gtr',
    GenAtlas: 'genAtlas',
    GenBank: 'genbank',
    'GenBank Nucleotide': 'genbankNucleotide',
    'GenBank Protein': 'genbankProtein',
    'Gene Role': 'geneRole',
    GeneCard: 'geneCard',
    'Genetic Testing Level': 'geneTestLevel',
    'Guideline Strength': 'guidelineStrength',
    'Guideline Tags': 'guidelineTags',
    'HC-SC': 'hcsc',
    HGNC: 'hgnc',
    HMDB: 'hmdb',
    'HumanCyc Gene': 'HumanCycGene',
    ISRCTN: 'isrctn',
    'IUPHAR Ligand': 'iupharLigand',
    'IUPHAR Receptor': 'iupharReceptor',
    'KEGG Compound': 'keggCompound',
    'KEGG Drug': 'keggDrug',
    'KEGG Pathway': 'keggPathway',
    LOINC: 'loinc',
    LRG: 'lrg',
    'Level of Evidence': 'levelsOfEvidence',
    MONDO: 'MONDO',
    MeSH: 'MeSH',
    MedDRA: 'MedDRA',
    'Metabolizer Option': 'metabolizer',
    ModBase: 'modBase',
    'NCBI Gene': 'entrezGene',
    NDC: 'ndc',
    'NDF-RT': 'NDFRT',
    OMIM: 'omim',
    PDB: 'pdb',
    'PDB Ligand': 'pdbLigand',
    'PGx Paper Types': 'pgxPaperTypes',
    'Pathway Categories': 'pathwayCategories',
    'Pathway Entities': 'pathwayEntities',
    'Pathway Processes': 'pathwayProcesses',
    PharmGKB: 'pharmgkb',
    'PharmGKB Tags': 'pgkbTags',
    PharmVar: 'pharmVar',
    'PharmVar Gene': 'pharmVarGene',
    'Phenotype Categories': 'phenoCat',
    'Phenotype Connecting words 1': 'connWords1',
    'Phenotype or Gene to Drug Connecting Words': 'connWords2',
    Polarity: 'polarity',
    Population: 'populationTerm',
    'Population Type': 'populationType',
    'Prescribing Change': 'rxChange',
    'PubChem BioAssay': 'pubchemBioAssay',
    'PubChem Compound': 'pubchemCompound',
    'PubChem Substance': 'pubchemSubstance',
    PubMed: 'pubMed',
    'PubMed Central': 'pmc',
    'Publisher Item Identifiers': 'pii',
    'Reactome Reaction': 'reactomeReaction',
    'RefSeq DNA': 'refSeqDna',
    'RefSeq Protein': 'refSeqProtein',
    'RefSeq RNA': 'refSeqRna',
    'Retirement Reason': 'retirement',
    RxNorm: 'RxNorm',
    'SPL Section': 'SPL',
    'SnoMed CT': 'SnoMedCT',
    'State Ontology': 'PvState',
    'Statistic Analysis': 'statAnalysis',
    'Statistical Test Tags': 'statTest',
    Swissmedic: 'swiss',
    'Therapeutic Targets Database': 'TTD',
    'Transmembrane Type': 'transmembraneTypes',
    'UCSC Genome Browser': 'ucscGenomeBrowser',
    UMLS: 'UMLS',
    UNII: 'unii',
    URL: 'url',
    UniProt: 'uniProtKb',
    Wikidata: 'wikidata',
    dbSNP: 'dbSnp',
  },
  editable: [
    'andOr',
    'yesNo',
    'drugBlackList',
    'cellularLocation',
    'pathwayEnzymaticModification',
    'pathwayEntities',
    'pathwayProcesses',
    'PvState',
    'transmembraneTypes',
    'chemicalRole',
    'levelsOfEvidence',
    'rxChange',
    'SPL',
    'retirement',
    'geneTestLevel',
    'guidelineStrength',
    'geneRole',
    'alleleFunction',
    'pathwayCategories',
    'guidelineTags',
    'significance',
    'association',
    'cellCat',
    'equality',
    'drug_ConnWords1',
    'fa_ConnWords1',
    'metabolizer',
    'phenoCat',
    'connWords1',
    'connWords2',
    'polarity',
    'populationTerm',
    'populationType',
    'statAnalysis',
    'statTest',
    'pgkbTags',
    'pgxPaperTypes',
  ],
  ontology: [
    'GO',
    'MeSH',
    'ATC',
    'SnoMedCT',
    'UMLS',
    'MedDRA',
    'RxNorm',
    'NDFRT',
    'andOr',
    'yesNo',
    'drugBlackList',
    'CL',
    'cellularLocation',
    'pathwayEnzymaticModification',
    'pathwayEntities',
    'pathwayProcesses',
    'PvState',
    'transmembraneTypes',
    'chemicalRole',
    'levelsOfEvidence',
    'rxChange',
    'SPL',
    'retirement',
    'geneTestLevel',
    'guidelineStrength',
    'geneRole',
    'alleleFunction',
    'pathwayCategories',
    'guidelineTags',
    'significance',
    'association',
    'cellCat',
    'equality',
    'drug_ConnWords1',
    'fa_ConnWords1',
    'metabolizer',
    'phenoCat',
    'connWords1',
    'connWords2',
    'polarity',
    'populationTerm',
    'populationType',
    'statAnalysis',
    'statTest',
    'loinc',
    'MONDO',
    'pgkbTags',
    'pgxPaperTypes',
  ],
};
export const LogicOperator = {
  shortName: {
    and: 'And',
    or: 'Or',
  },
  displayName: {
    And: 'and',
    Or: 'or',
  },
};
export const OntologyResource = {
  shortName: {
    GO: 'Gene Ontology',
    MeSH: 'MeSH',
    ATC: 'Anatomical Therapeutic Chemical Classification',
    SnoMedCT: 'SnoMed Clinical Terminology',
    UMLS: 'Unified Medical Language System',
    drugBlackList: 'Drug Name Black List',
    RxNorm: 'RxNorm',
    MedDRA: 'MedDRA',
    NDFRT: 'NDF-RT',
    loinc: 'LOINC',
    MONDO: 'MONDO',
    andOr: 'Collection Operator - And/Or',
    yesNo: 'Confirmation Operator - Yes/No',
    pathwayCategories: 'Pathway Categories',
    guidelineTags: 'Guideline Tags',
    alleleFunction: 'Allele Function',
    geneRole: 'Gene Role',
    levelsOfEvidence: 'Level of Evidence',
    SPL: 'SPL Section',
    geneTestLevel: 'Genetic Testing Level',
    guidelineStrength: 'Guideline Strength',
    CL: 'Cell Type Ontology',
    PvState: 'State Ontology',
    cellularLocation: 'Cellular Location',
    transmembraneTypes: 'Transmembrane Type',
    pathwayEntities: 'Pathway Entities',
    pathwayProcesses: 'Pathway Processes',
    pathwayEnzymaticModification: 'Enzymatic Modification',
    significance: 'Association Significance',
    association: 'Association Type',
    cellCat: 'Cell Type',
    equality: 'Equality Symbols',
    drug_ConnWords1: 'Drug Connecting words 1',
    fa_ConnWords1: 'Functional Assay Connecting words 1',
    metabolizer: 'Metabolizer Option',
    phenoCat: 'Phenotype Categories',
    connWords1: 'Phenotype Connecting words 1',
    connWords2: 'Phenotype or Gene to Drug Connecting Words',
    polarity: 'Polarity',
    populationTerm: 'Population',
    populationType: 'Population Type',
    statAnalysis: 'Statistic Analysis',
    statTest: 'Statistical Test Tags',
    chemicalRole: 'Chemical Role',
    rxChange: 'Prescribing Change',
    retirement: 'Retirement Reason',
    pgkbTags: 'PharmGKB Tags',
    pgxPaperTypes: 'PGx Paper Types',
  },
  displayName: {
    'Allele Function': 'alleleFunction',
    'Anatomical Therapeutic Chemical Classification': 'ATC',
    'Association Significance': 'significance',
    'Association Type': 'association',
    'Cell Type': 'cellCat',
    'Cell Type Ontology': 'CL',
    'Cellular Location': 'cellularLocation',
    'Chemical Role': 'chemicalRole',
    'Collection Operator - And/Or': 'andOr',
    'Confirmation Operator - Yes/No': 'yesNo',
    'Drug Connecting words 1': 'drug_ConnWords1',
    'Drug Name Black List': 'drugBlackList',
    'Enzymatic Modification': 'pathwayEnzymaticModification',
    'Equality Symbols': 'equality',
    'Functional Assay Connecting words 1': 'fa_ConnWords1',
    'Gene Ontology': 'GO',
    'Gene Role': 'geneRole',
    'Genetic Testing Level': 'geneTestLevel',
    'Guideline Strength': 'guidelineStrength',
    'Guideline Tags': 'guidelineTags',
    LOINC: 'loinc',
    'Level of Evidence': 'levelsOfEvidence',
    MONDO: 'MONDO',
    MeSH: 'MeSH',
    MedDRA: 'MedDRA',
    'Metabolizer Option': 'metabolizer',
    'NDF-RT': 'NDFRT',
    'PGx Paper Types': 'pgxPaperTypes',
    'Pathway Categories': 'pathwayCategories',
    'Pathway Entities': 'pathwayEntities',
    'Pathway Processes': 'pathwayProcesses',
    'PharmGKB Tags': 'pgkbTags',
    'Phenotype Categories': 'phenoCat',
    'Phenotype Connecting words 1': 'connWords1',
    'Phenotype or Gene to Drug Connecting Words': 'connWords2',
    Polarity: 'polarity',
    Population: 'populationTerm',
    'Population Type': 'populationType',
    'Prescribing Change': 'rxChange',
    'Retirement Reason': 'retirement',
    RxNorm: 'RxNorm',
    'SPL Section': 'SPL',
    'SnoMed Clinical Terminology': 'SnoMedCT',
    'State Ontology': 'PvState',
    'Statistic Analysis': 'statAnalysis',
    'Statistical Test Tags': 'statTest',
    'Transmembrane Type': 'transmembraneTypes',
    'Unified Medical Language System': 'UMLS',
  },
  editable: [
    'andOr',
    'yesNo',
    'drugBlackList',
    'cellularLocation',
    'pathwayEnzymaticModification',
    'pathwayEntities',
    'pathwayProcesses',
    'PvState',
    'transmembraneTypes',
    'chemicalRole',
    'levelsOfEvidence',
    'rxChange',
    'SPL',
    'retirement',
    'geneTestLevel',
    'guidelineStrength',
    'geneRole',
    'alleleFunction',
    'pathwayCategories',
    'guidelineTags',
    'significance',
    'association',
    'cellCat',
    'equality',
    'drug_ConnWords1',
    'fa_ConnWords1',
    'metabolizer',
    'phenoCat',
    'connWords1',
    'connWords2',
    'polarity',
    'populationTerm',
    'populationType',
    'statAnalysis',
    'statTest',
    'pgkbTags',
    'pgxPaperTypes',
  ],
};
export const MatchingMethod = {
  shortName: {
    unspecified: 'Unspecified',
    phenotype: 'Phenotype',
    activity: 'Activity Score',
    allele: 'Allele Status',
  },
  displayName: {
    'Activity Score': 'activity',
    'Allele Status': 'allele',
    Phenotype: 'phenotype',
    Unspecified: 'unspecified',
  },
};
export const GeneAlleleType = {
  shortName: {
    none: 'None',
    namedAlleles: 'Named Alleles',
    namedVariants: 'Named Variants',
  },
  displayName: {
    'Named Alleles': 'namedAlleles',
    'Named Variants': 'namedVariants',
    None: 'none',
  },
};
