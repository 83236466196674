/* global ENV */
import PropTypes from 'prop-types';

import brandmarkImage from 'images/logo/brandmark.svg';
import wordmarkPediatricImage from 'images/logo/wordmark-pediatric.svg';
import wordmarkImage from 'images/logo/wordmark.svg';

import './header.scss';

import Banner from 'components/Banner';
import AnnouncementBanner from 'components/Banner/Announcement';
import OnOffButton from 'components/Button/OnOff';
import SearchBar from 'components/Search/Bar';
import useEditContext from 'components/edit/EditContext';
import {FONTAWESOME_FOCUS_ICON} from 'components/icons/Focus';
import {FONTAWESOME_PEDIATRIC_ICON} from 'components/icons/Pediatric';
import Link from 'components/links/Link';
import Dropdown from 'components/root/dropdown';
import Svg from 'components/shared/Svg';
import {getTourType, hasTour, tourEventHandler} from 'components/tour/hook';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import usePediatricContext from 'context/Pediatric';


const propTypes = {
  isHome: PropTypes.bool,
};

export default function RootHeader({isHome = false}) {
  const appContext = useAppContext();
  const editContext = useEditContext();
  const pediatricContext = usePediatricContext();

  let loginUrl = appContext.getCurrentPath();
  if (loginUrl.indexOf('/user/login') === -1) {
    if (loginUrl.indexOf('/user/resetPassword') !== -1 ||
      loginUrl.indexOf('/user/resendConfirmation') !== -1) {
      loginUrl = '/user/login';
    } else {
      loginUrl = `/user/login?nextUrl=${encodeURIComponent(loginUrl)}`;
    }
  }

  const renderNoCookiesBanner = () => {
    if (!appContext.cookiesEnabled) {
      const driverName = appContext.cache.driver();
      if (!driverName || driverName === 'localforage-driver-memory') {
        return (
          <div className="noCookies alert alert-warning">
            <div className="container">
              <div className="noCookies__frame">
                You do not have cookies enabled. Some personalization features of this site will not work properly
                without this feature. We recommend that
                you <a href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/auto">enable cookies</a> for
                this site.
              </div>
            </div>
          </div>
        );
      }
    }
    return '';
  };

  const hamburgerMenuOptions = (
    <>
      <a className="drop-item" role="menuitem" tabIndex="0" href="/pgkbPublications">Publications</a>
      <Link newTab={true} className="drop-item" role="menuitem" tabIndex="0" href="https://blog.clinpgx.org">News</Link>
      <a className="drop-item" role="menuitem" tabIndex="0" href="/downloads">Downloads</a>
      <Link className="drop-item" role="menuitem" href="/feedback">Contact</Link>
    </>
  );

  let userMenu = null;
  if (appContext.isPreview) {
    // display user menu depending on sign-in state, only on preview
    if (appContext.user) {
      userMenu = (
        <Dropdown icon="user" label="Account">
          <span className="drop-item non-action">{appContext.user.email}</span>
          <a className="drop-item" role="menuitem" tabIndex="0" href="/curation">Curation Dashboard</a>
          <a className="drop-item" role="menuitem" tabIndex="0" href="/dev">Dev Dashboard</a>
          <a className="drop-item" role="menuitem" tabIndex="0" href="/user/edit">Edit Profile</a>
          <a className="drop-item" role="menuitem" tabIndex="0" href="/user/updatePassword">Change Password</a>
          {renderEditModeItem(editContext.isEditMode, editContext.toggleEditMode)}
          <a className="drop-item" role="menuitem" tabIndex="0" href="/user/logout">Sign Out</a>
        </Dropdown>
      );
    } else {
      userMenu = (
        <Dropdown icon={['far', 'user']} label="Account" className="account">
          <a className="drop-item account-link" role="menuitem" tabIndex="0" href={loginUrl}>Sign In</a>
        </Dropdown>
      );
    }
  }

  let focusIcon = ['far', FONTAWESOME_FOCUS_ICON];
  let focusIconSize;
  let focusClassname;
  if (pediatricContext.isPediatricMode) {
    focusIcon = FONTAWESOME_PEDIATRIC_ICON;
    focusIconSize = 'lg';
    focusClassname = 'nav-dropdown__focus--pediatric';
  }

  const wordmark = ENV === 'pediatric'
    ? <Svg className="wordmark" src={wordmarkPediatricImage} ariaLabel="PharmGKB Pediatric" />
    : <Svg className="wordmark" src={wordmarkImage} ariaLabel="PharmGKB" />;

  return (
    <header>
      {renderNoCookiesBanner()}
      <AnnouncementBanner />
      {renderTourBanner(appContext.getCurrentPath())}
      <div className="page-header">
        <div className="container">
          <nav className="page-header-nav" aria-label="Header">
            <a href="/" className="brand">
              <Svg className="logo" src={brandmarkImage} ariaHidden={true} />
              {wordmark}
              {appContext.isDevEnv && (<span className="logo-beta">{'\u03B2'}</span>)}
              {appContext.isPreview && (<b className="logo-preview">&nbsp;Preview</b>)}
            </a>

            {
              // if home display links, if not display search bar
              (isHome)
                ? (
                  <div className="links d-none d-md-flex">
                    <a className="nav-item nav-link" tabIndex="0" href="/pgkbPublications">Publications</a>
                    <Link newTab={true} className="nav-item nav-link" role="link" tabIndex="0" href="https://blog.clinpgx.org">Blog</Link>
                    <a className="nav-item nav-link" tabIndex="0" href="/downloads">Downloads</a>
                    <Link className="nav-item nav-link" href="/feedback">Contact</Link>
                  </div>
                )
                : (
                  <div className="search-bar-header d-print-none">
                    <SearchBar />
                  </div>
                )
            }

            <div className="nav-items d-print-none">
              {
                // if home, display hamburger menu on mobile only
                (isHome)
                  ? (
                    <Dropdown icon="bars" ariaLabel="Menu" className="mobile-nav">
                      {hamburgerMenuOptions}
                    </Dropdown>
                  )
                  : (
                    <Dropdown icon="bars" label="Menu" ariaLabel="Menu">
                      {hamburgerMenuOptions}
                    </Dropdown>
                  )
              }
              <Dropdown icon={focusIcon} label="Focus" className={focusClassname} iconSize={focusIconSize} id="focus">
                <a className="drop-item" role="menuitem" tabIndex="0" href="/page/focusIntro">Intro to Focus</a>
                <div className="drop-item">
                  <OnOffButton
                    label="Pediatric Focus"
                    on={pediatricContext.isPediatricMode}
                    stateful={false}
                    actionHandler={pediatricContext.togglePediatricMode}
                    role="menuitem"
                    tabIndex={0}
                  />
                </div>
                <a className="drop-item" role="menuitem" tabIndex="0" href="/page/PedsIntro">Pediatric Help</a>
                <a className="drop-item" role="menuitem" tabIndex="0" href="/pediatric/dashboard">Pediatric Dashboard</a>
              </Dropdown>
              { userMenu }
              <Dropdown icon={['far', 'question-circle']} label="Help" ariaLabel="Help Menu" className="help">
                <a className="drop-item" role="menuitem" tabIndex="0" href="/page/faqs">FAQs</a>
                {
                  hasTour(appContext.getCurrentPath()) &&
                    <a className="drop-item d-none d-md-flex" role="menuitem" tabIndex="0" onClick={tourEventHandler} onKeyDown={tourEventHandler}>Page Tour</a>
                }
                {
                  !!appContext.user &&
                  <a className="drop-item d-none d-md-flex" role="menuitem" tabIndex="0" href="/reset">Clear Cache</a>
                }
              </Dropdown>
            </div>

          </nav>
        </div>
      </div>
    </header>
  );
}
RootHeader.propTypes = propTypes;


/**
 * Renders edit mode menu item.
 *
 * @param {boolean} isEditMode
 * @param {Function} changeEditModeHandler
 */
function renderEditModeItem(isEditMode, changeEditModeHandler) {
  return (
    <div className="drop-item">
      <OnOffButton
        label="Edit Mode"
        on={isEditMode}
        stateful={false}
        actionHandler={changeEditModeHandler}
        role="menuitem"
        tabIndex={0}
      />
    </div>
  );
}

function renderTourBanner(currentPath) {
  if (!hasTour(currentPath)) {
    return null;
  }
  const tourComponent = getTourType(currentPath);
  const id = `tour-${tourComponent}`;
  let componentDisplayText = tourComponent;
  if (tourComponent === 'chemical') {
    if (currentPath.includes('clinicalAnnotation')) {
      componentDisplayText = 'clinical annotation with the pediatric focus';
    } else if (currentPath.includes('guidelineAnnotation')) {
      componentDisplayText = 'clinical guideline annotation with the pediatric focus on';
    } else {
      componentDisplayText = 'chemical overview';
    }
  } else if (tourComponent === 'vip') {
    componentDisplayText = 'very important pharmacogene';
  } else if (Types[tourComponent]) {
    componentDisplayText = Types[tourComponent].displayType.toLowerCase();
  }
  return (
    <Banner key={id} id={id} className="tourBanner">
      <p>
        New to the site?{' '}
        <button
          type="button"
          className="btn btn-link"
          onClick={tourEventHandler}
          onKeyDown={tourEventHandler}
        >
          Take a short tour
        </button>
        {' '}of the {componentDisplayText} page.
      </p>
    </Banner>
  );
}
