import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Button from 'components/Button';
import RecommendationAnnotation from 'components/RecommendationAnnotation';
import RecommendationAnnotationTags from 'components/RecommendationAnnotation/Tags';
import Link from 'components/links/Link';
import Types, {getBaseUrl} from 'conf/types';
import {closeIcon, openIcon} from 'pages/genotypeResults/DrugResultGrid';

import 'pages/genotypeResults/DrugResultGridGenotypeResult.scss';

/**
 * A component to display a genotype result (i.e. a RecommendationAnnotation with additional data) in the context of
 * the drug result grid.
 */
export default function DrugResultGridGenotypeResult({
  id, implications = [], text, genotypes, phenotypes, lookupKey,
  classification, population = 'unspecified', alternateDrugAvailable, dosingInformation, otherPrescribingGuidance,
  open, onOpenChange, multiPopulation = false, source, parentCls, parentSource,
}) {
  const iconDisclose = open ? closeIcon : openIcon;
  const altText = open ? 'close details' : 'open details';

  const moreUrl = getBaseUrl(source.id, source.objCls);
  const recConfig = {
    implications,
    text,
    genotypes,
    phenotypes,
    lookupKey,
    classification,
    population,
    alternateDrugAvailable,
    dosingInformation,
    otherPrescribingGuidance,
  };
  let moreText;
  if (parentCls === Types.pGxAssociation.displayType) {
    moreText = 'see full table…';
    recConfig.populationTitle = 'Affected Subgroup';
    recConfig.recommendationTitle = 'Description of Interaction';
  } else {
    moreText = 'read full annotation…';
  }

  return (
    <div className="mb-3">
      <div style={{display: 'grid', gridTemplateColumns: 'fit-content(2em) 1fr'}}>
        <div className="mr-1">
          <Button actionHandler={() => onOpenChange(id, !open)} iconOnly={true} title={altText}>
            <FontAwesomeIcon icon={iconDisclose} />
          </Button>
        </div>
        {open && (
          <div>
            <RecommendationAnnotation {...recConfig} multipopulation={multiPopulation} parentCls={parentCls} parentSource={parentSource} />
            <Link href={moreUrl} newTab={true}>{moreText}</Link>
          </div>
        )}
        {!open && (
          <div>
            {multiPopulation && <small className="font-weight-bold">{population}</small>}
            <RecommendationAnnotationTags
              alternateDrugAvailable={alternateDrugAvailable}
              dosingInformation={dosingInformation}
              otherPrescribingGuidance={otherPrescribingGuidance}
              parentCls={parentCls}
            />
          </div>
        )}
      </div>
    </div>
  );
}
