import clsx from 'clsx';
import {parseISO} from 'date-fns';
import {format as dateFormat} from 'date-fns/format';
import {filter, flatMap, map} from 'lodash';

import EditButton from 'components/Button/Edit';
import LiteratureCitation from 'components/Literature/Citation';
import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import SimpleTable from 'components/Table/Simple';
import {fixedColumnStyle, flexColumnStyle} from 'components/Table/columnHelpers';
import AmpTierTag from 'components/Tag/AmpTier';
import useEditContext from 'components/edit/EditContext';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import DownloadLink from 'components/links/Download';
import ResourceLink from 'components/links/Resource';
import CuratorOnly from 'components/shared/curator_only';
import HtmlContainer from 'components/shared/html_container';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';

import './index.scss';

export default function TestingPage() {
  const appContext = useAppContext();
  const editContext = useEditContext();
  const {response, error} = useGet('site/testing',
    {
      searchParams: {view: 'most', sources: 'AMP'},
      cacheResponse: false,
      parseJson: true,
    });
  let body;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (!response) {
    body = <Loading />;
  } else {
    const allHistory = filter(flatMap(response.data, (d) => d.history), (h) => h.visibleToAll);
    body = (
      <section id="AMP">
        <InlinePage id="ampAllelesToTest" />
        <CuratorOnly className={clsx({'d-none': !editContext.isEditMode})}>
          <p>To add a new gene/publication, go to the individual literature page and add a &quot;Testing&quot; section.</p>
          <p>To add haplotypes or variants, go to the individual haplotype/variant page and edit it to set the AMP tier</p>
        </CuratorOnly>
        <div>
          <DownloadLink href={appContext.apiUrl('/site/testing/_tsv')} className="btn btn-outline-secondary">Download</DownloadLink>
        </div>
        <div className="testingList">
          <div className="testingListRow header">
            <div>
              <div>Subjects</div>
            </div>
            <div>
              <div>Guideline</div>
            </div>
            <div>
              <div>Minimum Set</div>
              <div><AmpTierTag tier="Tier 1" tooltipText="See guideline manuscripts for exact criteria for Tiers in that guideline." tooltipUrl="" /></div>
            </div>
            <div>
              <div>Optional</div>
              <div><AmpTierTag tier="Tier 2" tooltipText="See guideline manuscripts for exact criteria for Tiers in that guideline." tooltipUrl="" /></div>
            </div>
          </div>
          {map(response.data, (d) => (
            <div className="testingListRow" key={d.id}>
              <TestingTargets {...d} />
              <TestingDataAnnotation {...d} />
              <TestingTierTargets {...d} tierFilter="Tier 1" />
              <TestingTierTargets {...d} tierFilter="Tier 2" />
            </div>
          ))}
        </div>
        <div>
          <h4>History</h4>
          <SimpleTable
            className="table-sm"
            columns={[
              {
                id: 'date',
                accessor: (row) => parseISO(row.date),
                Header: 'Date',
                Cell: ({value}) => dateFormat(value, 'MM/dd/yyyy'),
                sortable: true,
                sortType: 'datetime',
                ...fixedColumnStyle(100),
              },
              {
                accessor: 'type',
                Header: 'Type',
                ...fixedColumnStyle(90),
              },
              {
                accessor: 'description',
                Header: 'Comment',
                ...flexColumnStyle(120),
              },
            ]}
            data={allHistory}
            tableProps={{initialState: {sortBy: [{id: 'date', desc: true}]}}}
          />
        </div>
      </section>
    );
  }
  
  return (
    <ReadableWidthTemplate title="AMP's Minimum Sets of Alleles for PGx Testing">
      {body}
    </ReadableWidthTemplate>
  );
}

function TestingDataAnnotation({literature, markdown}) {
  return (
    <div>
      {map(literature, (l) => <LiteratureCitation key={l.id} {...l} firstAuthorOnly={true} italicizeTitle={true} />)}
      <HtmlContainer html={markdown.html} className="mt-2 p-2" />
    </div>
  );
}

function TestingTargets({id, targets}) {
  return (
    <div>
      {map(targets, (t) => <div key={t.id}><ResourceLink resource={t} /></div>)}
      <EditControls>
        <EditButton href={`/edit/dataAnnotation/${id}`} label="Edit" />
      </EditControls>
    </div>
  );
}

function TestingTierTargets({tierTargets, tierFilter}) {
  const filteredTargets = filter(tierTargets, (t) => t.ampTier === tierFilter);
  return (
    <div>
      {map(filteredTargets, (t) => <div key={t.id}> <ResourceLink resource={t} /></div>)}
    </div>
  );
}
