import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useState} from 'react';

import './OnOff.scss';
import {createActionHandler} from 'components/Button/index';


const propTypes = {
  label: PropTypes.string.isRequired,
  /**
   * Indicates if this button is on (true) or off (false).
   */
  on: PropTypes.bool,
  /**
   * Indicates if this component should maintain state internally, or just reflect `on` property.
   */
  stateful: PropTypes.bool,
  actionHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  role: PropTypes.string,
  tabIndex: PropTypes.number,
};

export default function OnOffButton({label, on = true, stateful = true, actionHandler, className, role = 'button',
  tabIndex}) {
  const [status, setStatus] = useState(on);

  const checked = stateful ? status : on;
  const changeHandler = createActionHandler(() => {
    if (stateful) {
      setStatus(!status);
    }
    actionHandler(!checked);
  });

  const params = {};
  if (tabIndex) {
    params.tabIndex = tabIndex;
  }
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      id="onOffButton"
      className={clsx('onOffButton', className)}
      role={role}
      onClick={changeHandler}
      onKeyDown={changeHandler}
      {...params}
    >
      <span>{label}</span>
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={changeHandler} />
        <span className="switch__slider" data-unchecked="off" data-checked="on" />
      </label>
    </div>
  );
}
OnOffButton.propTypes = propTypes;
