import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {compact, filter, map, pickBy, reduce, size, sortBy, sortedUniq} from 'lodash';
import {useState} from 'react';
import InlineSVG from 'react-inlinesvg';
import {toast} from 'react-toastify';
import {renderSVG} from 'uqr';

import Button from 'components/Button';
import EditIcon from 'components/icons/Edit';
import UndoIcon from 'components/icons/Undo';
import InfoLink from 'components/links/Info';
import Link from 'components/links/Link';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import {analyticsEvent} from 'helpers/analytics';
import copyToClipboard from 'helpers/clipboard';
import DrugResultGridSource from 'pages/genotypeResults/DrugResultGridSource';
import {caseInsensitiveComparator} from 'utils/comparators';

import 'pages/genotypeResults/DrugResultGrid.scss';

export const openIcon = ['far', 'plus-square'];
export const closeIcon = ['far', 'minus-square'];


/**
 * A component for showing a grid of all matching genotype results grouped by drug then by source then by annotation.
 */
export default function DrugResultGrid({
  drugs, geneAlleleMap, alleles, phenotypes, onOpenChange, hideNoRec: hideNoRecParam,
}) {
  const appContext = useAppContext();
  const [hideNoRec, setHideNoRec] = useState(hideNoRecParam);

  if (size(drugs) === 0) {
    return null;
  }

  const nRecDrugs = size(filter(drugs, (d) => !d.notActionable));
  const nAllDrugs = size(drugs);

  const enteredGenes = [];
  if (alleles) {
    enteredGenes.push(...Object.keys(alleles));
  }
  if (phenotypes) {
    enteredGenes.push(...Object.keys(phenotypes));
  }
  const filteredDrugs = pickBy(drugs, (d) => !hideNoRec || !d.notActionable);

  const groups = reduce(filteredDrugs, (a, drug) => {
    const newA = {...a};
    for (let i = 0; i < drug.groups.length; i += 1) {
      const group = drug.groups[i][0];
      newA[group] = [...(a[group] || []), drug.drug.name];
    }
    return newA;
  }, {});

  const handleDrugScroll = (drugName) => () => {
    document.getElementById(`drug-${drugName}`).scrollIntoView({behavior: 'smooth'});
    analyticsEvent('gsi-scrollToDrug', {drug: drugName});
  };

  let placeholderComp;
  if (size(drugs) > 0 && size(filteredDrugs) === 0) {
    placeholderComp = (
      <div className="grid-placeholder">
        No recommended clinical action or guideline with a recommendation has been found for the genotypes specified.
      </div>
    );
  } else if (size(drugs) === 0) {
    placeholderComp = (
      <div className="grid-placeholder">
        No guideline data found.
      </div>
    );
  }
  const changeGenotypeUrl = `/genotype?q=${encodeURIComponent(JSON.stringify(geneAlleleMap))}&hideNoRec=${hideNoRec ? 'true' : 'false'}`;

  const handleNoRecToggle = (noRecVal) => () => {
    setHideNoRec(noRecVal);
    analyticsEvent('gsi-hideNoRec', {switchTo: noRecVal ? 'show' : 'hide'});
    const newUrl = appContext.getCurrentPath().split('?')[0] + '?q=' +
      encodeURIComponent(JSON.stringify(geneAlleleMap)) + '&hideNoRec=' + noRecVal;
    appContext.redirect(newUrl, true);
  };

  const handlePrint = () => {
    analyticsEvent('gsi-print');
    // eslint-disable-next-line no-restricted-globals
    print();
  };

  return (
    <div>
      <div className="container mb-4">
        <h3>Selected Genotypes</h3>
        <div className="phenotype-summary-list">
          <div className="phenotype-summary-item--head">Gene</div>
          <div className="phenotype-summary-item--head">Genotype</div>
          <div className="phenotype-summary-item--head">Phenotype</div>
          {map(sortedUniq(enteredGenes.sort(caseInsensitiveComparator)), (geneSymbol) => (
            <>
              <div className="phenotype-summary-item--head" key={geneSymbol}>
                {geneSymbol}
              </div>
              <div key={`dip-${geneSymbol}`}>
                <AlleleDisplay alleles={geneAlleleMap[geneSymbol].slice(0, 2)} />
              </div>
              <div key={`phe-${geneSymbol}`}>
                {phenotypes[geneSymbol]}
              </div>
            </>
          ))}
        </div>
        <div className="d-print-none mt-1">
          <Button href={changeGenotypeUrl}><EditIcon /> Change</Button>
          <Button href="/genotype" className="btn-outline-secondary"><UndoIcon /> Start Over</Button>
        </div>
      </div>

      <div className="container d-none d-print-block mb-3">
        <h3>Web link</h3>
        <p>To see the current report for this genotype online, point your camera at this QR code.</p>
        <div style={{width: '15rem'}}><InlineSVG src={renderSVG(document.URL)} /></div>
      </div>

      <div className="container mb-3">
        <h3 className="mb-1">
          Annotated Drugs
          {' '}
          <span className="list-count">({Object.keys(filteredDrugs).length} unique)</span>
          {' '}
          <InfoLink href="https://www.whocc.no/atc/structure_and_principles/" tooltip="Grouped by ATC therapeutic classification" iconSize="sm" className="d-print-none" />
        </h3>
        <div className="d-print-none mb-2">
          <label htmlFor="drugVisibilityAction" className="mr-3">
            <input type="radio" id="drugVisibilityAction" name="noRecControl" onChange={handleNoRecToggle(true)} checked={hideNoRec} /> Show {nRecDrugs} actionable drugs
          </label>
          <label htmlFor="drugVisibilityAll">
            <input type="radio" id="drugVisibilityAll" name="noRecControl" onChange={handleNoRecToggle(false)} checked={!hideNoRec} /> Show all {nAllDrugs} drugs with guidance related to the genes selected
          </label>
        </div>
        <dl className="facts facts--small row drug-group-list">
          {map(sortBy(Object.keys(groups)), (g) => (
            <>
              <dt key={`dt-${g}`} className="col-sm-4">
                {g}
              </dt>
              <dd key={`dd-${g}`} className="col-sm-8 drug-summary-list">
                {map(groups[g], (drugName) => (
                  <div className="drug-summary-drug" key={`drugName${drugName}`}>
                    <Button className="btn-link" actionHandler={handleDrugScroll(drugName)}>{drugName}</Button>
                  </div>
                ))}
              </dd>
            </>
          ))}
        </dl>
      </div>


      <div className="row d-print-none">
        <div className="col">
          <Button actionHandler={() => onOpenChange(null, true)} className="btn-outline-secondary btn-sm">
            <FontAwesomeIcon icon={openIcon} /> Expand All
          </Button>
          <Button actionHandler={() => onOpenChange(null, false)} className="btn-outline-secondary btn-sm">
            <FontAwesomeIcon icon={closeIcon} /> Collapse All
          </Button>

          <span className="ml-2 font-italic">
            Click the <FontAwesomeIcon icon={openIcon} /> icons to see more
          </span>
        </div>
        <div className="col text-right">
          <button
            className="btn btn-outline-secondary copyButton"
            onClick={(e) => {
              copyToClipboard(e);
              analyticsEvent('gsi-shareResults');
              toast.success('Copied link');
            }}
            data-clipboard-text={appContext.getCurrentUrl()}
          >
            <FontAwesomeIcon icon={['fas', 'share-square']} /> Copy Link to These Results
          </button>
          {/* eslint-disable-next-line no-restricted-globals */}
          <Button actionHandler={handlePrint} className="btn btn-outline-secondary">
            <FontAwesomeIcon icon={['fas', 'print']} /> Print
          </Button>
        </div>
      </div>

      <div className="drug-summary-table">
        <div className="col-header" key="cpicHeader">
          CPIC
          {' '}
          <InfoLink href="/page/cpic" tooltip="Learn more about CPIC" iconSize="xs" newTab={true} className="d-print-none" />
        </div>
        <div className="col-header" key="dpwgHeader">
          DPWG
          {' '}
          <InfoLink href="/page/dpwg" tooltip="Learn more about DPWG" iconSize="xs" newTab={true} className="d-print-none" />
        </div>
        <div className="col-header" key="fdaHeader">
          FDA Label Annotations
          {' '}
          <InfoLink href="/page/fda" tooltip="Learn more about FDA" iconSize="xs" newTab={true} className="d-print-none" />
        </div>
        <div className="col-header" key="pgxAssocHeader">
          FDA Table of Pharmacogenetic Associations
          {' '}
          <InfoLink href="/page/fda" tooltip="Learn more about FDA" iconSize="xs" newTab={true} className="d-print-none" />
        </div>

        {placeholderComp}

        {map(Object.keys(filteredDrugs).sort(caseInsensitiveComparator), (drugName) => {
          const {drug, cpic, dpwg, fda, fdaPgx, notActionable} = filteredDrugs[drugName];
          const drugId = drug.id;

          if (hideNoRec && notActionable) {
            return null;
          }

          return (
            <>
              <div className="row-header" id={`drug-${drugName}`} key={`${drugId}-drugCell`}>
                {drugId && <Link href={Types.chemical.url(drugId) + '/prescribingInfo'} newTab={true}>{drugName}</Link>}
                {!drugId && <div>{drugName}</div>}
              </div>
              <DrugResultGridSource drug={drug} source={cpic} onOpenChange={onOpenChange} key={`${drugId}-cpicCell`} />
              <DrugResultGridSource drug={drug} source={dpwg} onOpenChange={onOpenChange} key={`${drugId}-dpwgCell`} />
              <DrugResultGridSource drug={drug} source={fda} onOpenChange={onOpenChange} key={`${drugId}-fdaCell`} />
              <DrugResultGridSource drug={drug} source={fdaPgx} onOpenChange={onOpenChange} key={`${drugId}-pgxAssocCell`} />
            </>
          );
        })}
      </div>
    </div>
  );
}

function AlleleDisplay({alleles = []}) {
  const normalized = compact(alleles);
  if (normalized.length === 0) {
    return <em>unspecified</em>;
  }
  return <span>{normalized.join('/')}</span>;
}
