import {filter, forEach, map, size} from 'lodash';
import {useEffect} from 'react';

import ApproveAndDiffButtons from 'components/Button/ApproveAndDiff';
import EditButton from 'components/Button/Edit';
import GuidelineAnnotationPicker from 'components/GuidelineAnnotation/GuidelinePicker';
import HistoryTable from 'components/History/Table';
import Loading from 'components/Loading';
import PharmgkbTagButton from 'components/PharmgkbTag/Button';
import Section from 'components/Section';
import AlternateDrugTag from 'components/Tag/AlternateDrug';
import DosingInfoTag from 'components/Tag/DosingInfo';
import TagHeader from 'components/Tag/Header';
import OtherGuidanceTag from 'components/Tag/OtherGuidance';
import PediatricTag from 'components/Tag/Pediatric';
import RetiredTag from 'components/Tag/Retired';
import TestingInfoTag from 'components/Tag/TestingInfo';
import EditControls from 'components/edit/EditControls';
import Link from 'components/links/Link';
import NotFound from 'components/shared/error/not_found';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import HtmlContainer, {addHtmlContainerWhenLoaded} from 'components/shared/html_container';
import TableStylizer from 'components/shared/tableStylizer';
import YoutubeEmbed from 'components/shared/youtube_embed';
import {useTour} from 'components/tour/hook';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import usePediatricContext from 'context/Pediatric';
import {useGet} from 'helpers/KyHooks';

export default function GuidelineAnnotationDrugDetail({guidelineId, drugId}) {
  const appContext = useAppContext();
  const pediatricContext = usePediatricContext();
  const {response, error} = useGet(`site/guidelineAnnotation/${guidelineId}`, {
    cacheResponse: !appContext.isPreview,
  });

  const readyForTour = useTour('guidelineAnnotationWithFocus');
  useEffect(() => {
    if (response) {
      readyForTour();
    }
  }, [readyForTour, response]);

  if (error) {
    if (error.response?.status === 404) {
      return <NotFound />;
    }
  } else if (response && drugId) {
    const {guideline, relatedChemicals: otherChemicals, gsiAlleles, approved, published} = response.data;
    const {history, recommendation = true,
      hasTestingInfo = false,
      dosingInformation = false,
      alternateDrugAvailable = false,
      otherPrescribingGuidance = false,
      pediatric, pediatricMarkdown, relatedGenes, retirementReason, userId} = guideline;
    const drugsOnSameAnnotation = filter(guideline.relatedChemicals, ({id}) => id !== drugId);

    const {name, descriptiveVideoId, sections, source, summaryMarkdown, textMarkdown} = guideline;
    const renderPicker = () => {
      if (size(gsiAlleles) > 0) {
        const note = sections?.alleleNotes?.[0]?.markdown?.html ? <HtmlContainer html={sections?.alleleNotes[0]?.markdown.html} /> : null;
        return (
          <GuidelineAnnotationPicker
            guidelineId={guidelineId}
            alleleNote={note}
            source={source}
            alleles={gsiAlleles}
            chemicalId={drugId}
          />
        );
      }
      return null;
    };

    const editButtons = [
      <EditButton href={`/edit/guidelineAnnotation/${guidelineId}`} key="edit" />,
      <EditButton href={`/edit/guidelineAnnotation/${guidelineId}/recommendations`} label="Recommendations" key="editRec" />,
    ];
    forEach(
      relatedGenes,
      (gene) => editButtons.push(<EditButton href={`/edit/haplotypes/${gene.id}`} label={`Edit ${gene.symbol} Alleles`} key={gene.id} />),
    );
    editButtons.push(<ApproveAndDiffButtons objCls="GuidelineAnnotation" objId={guidelineId} approved={approved} published={published} iconOnly={false} key="approvedDiffBtn" />);
    editButtons.push(<PharmgkbTagButton objId={guidelineId} objCls="guidelineAnnotation" key="pharmgkbTagBtn" />);
    editButtons.push(<span className="ml-2 mr-2 fixed-width-font" key="userId">owner: {userId || <em>unassigned</em>}</span>);

    return (
      <>
        <EditControls>
          {editButtons}
        </EditControls>
        
        <h3>{name}</h3>

        <TagHeader>
          {retirementReason && <RetiredTag />}
          {hasTestingInfo ? <TestingInfoTag /> : null}
          {dosingInformation ? <DosingInfoTag context="guidelineAnnotation" /> : null}
          {alternateDrugAvailable ? <AlternateDrugTag context="guidelineAnnotation" /> : null}
          {otherPrescribingGuidance ? <OtherGuidanceTag context="guidelineAnnotation" /> : null}
          {pediatric && <PediatricTag />}
        </TagHeader>

        <Section title="Summary">
          {addHtmlContainerWhenLoaded(summaryMarkdown?.html, {className: 'summary-text'})}
        </Section>

        {!recommendation && <div className="alert alert-danger mb-5">No recommendation</div>}

        {renderPicker()}

        {
          pediatricContext.isPediatricMode && pediatric && pediatricMarkdown && (
            <Section title="Pediatric Excerpts" className="alert alert-info tour-pediatric-excerpts">
              <HtmlContainer html={pediatricMarkdown.html} className="summary-text" />
            </Section>
          )
        }

        <Section title="Annotation">
          {descriptiveVideoId && <YoutubeEmbed videoId={descriptiveVideoId} />}
          <TableStylizer isInSidebarLayout={true} html={textMarkdown?.html} />
        </Section>

        {size(drugsOnSameAnnotation) > 0 && (
          <FactSection title="Chemicals Also In This Annotation">
            <ul className="list-inline">
              {map(drugsOnSameAnnotation, (otherDrug) => (
                <li key={otherDrug.id} className="list-inline-item"><Link href={`/chemical/${otherDrug.id}/guidelineAnnotation/${guidelineId}`}>{otherDrug.name}</Link></li>
              ))}
            </ul>
          </FactSection>
        )}
        {source === 'CPIC' && recommendation && size(otherChemicals) > 0 && (
          <FactSection title="Other Chemicals In This Guideline">
            <ul className="list-inline">
              {map(otherChemicals, (otherDrug) => (
                <li key={otherDrug.chemical.id} className="list-inline-item"><Link href={`/chemical/${otherDrug.chemical.id}/guidelineAnnotation/${otherDrug.guidelineId}`}>{otherDrug.chemical.name}</Link></li>
              ))}
            </ul>
          </FactSection>
        )}
        <p><Link href={Types.guidelineAnnotation.url(guidelineId)}>See the full annotation</Link></p>

        <Fact label="PharmGKB ID" literal={guidelineId} inline={true} />

        <h3>History</h3>
        <HistoryTable history={history} parentCls={guideline.objCls} parentId={guidelineId} />
      </>
    );
  } else {
    return <Loading />;
  }
}
