import clsx from 'clsx';
import PropTypes from 'prop-types';

import Tag from 'components/Tag/index';

const propTypes = {
  tier: PropTypes.string,
  className: PropTypes.string,
};

export default function AmpTierTag({tier, className, tooltipText = 'Learn more about AMP tiers', tooltipUrl = '/ampAllelesToTest'}) {
  return (
    <Tag
      className={clsx('tag--secondary', className)}
      tooltipUrl={tooltipUrl}
      tooltipText={tooltipText}
    >
      AMP {tier}
    </Tag>
  );
}
AmpTierTag.propTypes = propTypes;
