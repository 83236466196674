import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import ClinicalAnnotationsBarChart from 'components/ClinicalAnnotation/BarChart';
import ClinicalAnnotationsTabIntro from 'components/ClinicalAnnotation/TabIntro';
import ClinicalAnnotationTable from 'components/ClinicalAnnotation/Table';
import Loading from 'components/Loading';
import {TableType} from 'components/Table/utils';
import KyError from 'components/errors/KyError';
import {useTour} from 'components/tour/hook';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  objCls: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default function ClinicalAnnotationsPediatricTab({objCls, id}) {
  const appContext = useAppContext();
  const target = objCls === 'combination' ? id : `${objCls}/${id}`;
  const [filter, setFilter] = useState(null);
  const {response: pedResponse, error: pedError} = useGet(`site/tab/clinicalAnnotations/${target}`, {
    searchParams: {
      pediatric: true,
    },
    cacheResponse: !appContext.isPreview,
  });
  const {response, error} = useGet(`site/tab/clinicalAnnotations/${target}`, {
    searchParams: {
      pediatric: false,
    },
    cacheResponse: !appContext.isPreview,
  });

  const readyForTour = useTour('clinicalAnnotationsTabWithFocus');
  useEffect(() => {
    if (response && pedResponse) {
      readyForTour();
    }
  }, [readyForTour, response, pedResponse]);

  let pedTable = <Loading />;
  let barChart = <div className="caFigure caFigure--loading figure figure--floatRight"><Loading /></div>;

  if (pedError) {
    pedTable = <KyError kyError={pedError} />;
  } else if (pedResponse) {
    if (pedResponse?.data?.length > 0) {
      const type = pedResponse?.data?.length <= 6 ? TableType.SIMPLE : TableType.VIRTUALIZED;
      pedTable = (
        <ClinicalAnnotationTable
          id="pediatric-clinicalAnnotations"
          data={pedResponse?.data}
          objCls={objCls}
          showPediatricColumn={false}
          showPediatricOnlyColumn={true}
          type={type}
          height="400px"
          updatedFilters={filter}
        />
      );
      barChart = (
        <ClinicalAnnotationsBarChart
          clinicalAnnotations={pedResponse?.data}
          filterChangeFn={setFilter}
          captionTitle="Pediatric annotations by "
          className="figure--floatRight"
        />
      );
    } else {
      pedTable = <p className="noData">No pediatric clinical annotations.</p>;
      barChart = null;
    }
  }
  let nonPedTable = <Loading />;
  if (error) {
    nonPedTable = <KyError kyError={error} />;
  } else if (response) {
    if (response?.data?.length > 0) {
      const type = response?.data?.length <= 6 ? TableType.SIMPLE : TableType.VIRTUALIZED;
      nonPedTable = (
        <ClinicalAnnotationTable
          id="nonPediatric-clinicalAnnotations"
          data={response?.data}
          objCls={objCls}
          showPediatricColumn={false}
          showPediatricOnlyColumn={true}
          type={type}
          height="400px"
        />
      );
    } else {
      nonPedTable = <p className="noData">No non-pediatric clinical annotations.</p>;
    }
  }

  return (
    <div className="variantAnnotationsTab">
      <ClinicalAnnotationsTabIntro id={id} chart={barChart} />

      <div className="tour-pediatric-clinical-annotation-table">
        <h4>Pediatric Clinical Annotations</h4>
        {pedTable}
      </div>


      <h4 className="mt-5">Non-Pediatric Clinical Annotations</h4>
      {nonPedTable}
    </div>
  );
}
ClinicalAnnotationsPediatricTab.propTypes = propTypes;
