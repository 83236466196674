import {filter, find, forEach, size} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useMemo, useState} from 'react';

import './DrugTable.scss';
import Button from 'components/Button';
import {baseAccIdColumn} from 'components/Table/Cell/AccId';
import VirtualizedTable from 'components/Table/Virtualized';
import AlternateDrugTag from 'components/Tag/AlternateDrug';
import BiomarkerTag, {isBiomarker, isExBiomarker} from 'components/Tag/Biomarker';
import DosingInfoTag from 'components/Tag/DosingInfo';
import {CancerTag, IndicationTag, PrescribingTag} from 'components/Tag/LabelAnnotation';
import OtherGuidanceTag from 'components/Tag/OtherGuidance';
import PediatricTag from 'components/Tag/Pediatric';
import InfoIcon from 'components/icons/Info';
import {DataSource} from 'conf/enums';
import usePediatricContext from 'context/Pediatric';
import LevelFilter, {pgxLevelFilter} from 'pages/LabelAnnotations/LevelFilter';
import SourceCell, {sourceExportValue} from 'pages/LabelAnnotations/SourceCell';


const sources = {
  FDA: {
    displayName: 'FDA',
    id: 'fda',
    Cell: SourceCell,
    Filter: LevelFilter,
    filter: pgxLevelFilter,
    getCellExportValue: sourceExportValue,
  },
  EMA: {
    displayName: 'EMA',
    id: 'ema',
    Cell: SourceCell,
    Filter: LevelFilter,
    filter: pgxLevelFilter,
    getCellExportValue: sourceExportValue,
  },
  HCSC: {
    displayName: 'HCSC',
    id: 'hcsc',
    Cell: SourceCell,
    Filter: LevelFilter,
    filter: pgxLevelFilter,
    getCellExportValue: sourceExportValue,
  },
  Swissmedic: {
    displayName: 'SWISSMEDIC',
    id: 'swissmedic',
    Cell: SourceCell,
    Filter: LevelFilter,
    filter: pgxLevelFilter,
    getCellExportValue: sourceExportValue,
  },
  PMDA: {
    displayName: 'PMDA',
    id: 'pmda',
    accessor: sourceAccessor('pmda'),
    Cell: SourceCell,
    Filter: LevelFilter,
    filter: pgxLevelFilter,
    getCellExportValue: sourceExportValue,
  },
};

const initialState = {
  sortBy: [
    {
      id: 'drug',
      desc: false,
    },
  ],
};


const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  updatedFilters: PropTypes.arrayOf(PropTypes.object),
  showSwissMedicAndPMDALabels: PropTypes.bool,
};
/**
 * Renders a table of label annotations based on drugs.
 */
export default function LabelAnnotationDrugTable({data, updatedFilters, showSwissMedicAndPMDALabels}) {
  const pediatricContext = usePediatricContext();
  const [filters, setFilters] = useState({
    biomarker: false,
    exBiomarker: false,
    altDrug: false,
    dosing: false,
    otherGuidance: false,
    cancer: false,
    prescribing: false,
    pediatric: false,
    indication: false,
  });

  const counts = useMemo(() => {
    const rez = {
      FDA: 0,
      EMA: 0,
      Swissmedic: 0,
      HCSC: 0,
      PMDA: 0,
      biomarker: 0,
      chemicals: 0,
    };
    forEach(data, (c) => {
      rez.FDA += size(c.labels.FDA);
      rez.EMA += size(c.labels.EMA);
      rez.Swissmedic += size(c.labels.Swissmedic);
      rez.HCSC += size(c.labels.HCSC);
      rez.PMDA += size(c.labels.PMDA);
      rez.chemicals += 1;
      rez.biomarker += (isBiomarker(c.biomarkerText) ? 1 : 0);
    });
    return rez;
  }, [data]);

  const columns = useMemo(() => {
    const cols = [
      {
        id: 'drug',
        Header: (origTable) => renderHeader('Drugs', 'drugs', origTable),
        accessor: 'drug',
        ...baseAccIdColumn,
      },
    ];

    const srcKeys = Object.keys(sources);
    // add columns based on sources
    for (let x = 0; x < srcKeys.length; x += 1) {
      const src = srcKeys[x];
      if (counts[src] > 0) {
        if (!showSwissMedicAndPMDALabels &&
          (src === DataSource.displayName.Swissmedic ||
            src === DataSource.displayName['Pharmaceuticals and Medical Devices Agency, Japan'])) {
          continue;
        }
        const {displayName, ...colProps} = sources[src];
        cols.push({
          ...colProps,
          Header: (origTable) => renderHeader(displayName, src, origTable),
          accessor: sourceAccessor(src, filters),
        });
      }
    }
    return cols;
  }, [counts]);

  const rows = useMemo(() => {
    if (
      !filters.biomarker &&
      !filters.exBiomarker &&
      !filters.altDrug &&
      !filters.dosing &&
      !filters.otherGuidance &&
      !filters.cancer &&
      !filters.prescribing &&
      !filters.pediatric &&
      !filters.indication
    ) {
      return data;
    }
    const rez = [];
    for (let x = 0; x < data.length; x += 1) {
      const row = data[x];
      const fda = filter(row.labels.FDA, (l) => filterLabel(filters, l));
      const ema = filter(row.labels.EMA, (l) => filterLabel(filters, l));
      const swissmedic = filter(row.labels.Swissmedic, (l) => filterLabel(filters, l));
      const hcsc = filter(row.labels.HCSC, (l) => filterLabel(filters, l));
      const pmda = filter(row.labels.PMDA, (l) => filterLabel(filters, l));
      if (fda.length + ema.length + swissmedic.length + hcsc.length + pmda.length > 0) {
        rez.push({...row, fda, ema, swissmedic, hcsc, pmda});
      }
    }
    return rez;
  }, [data, filters]);

  useEffect(() => {
    if (pediatricContext.isPediatricMode) {
      setFilters({...filters, pediatric: true});
    } else {
      setFilters({...filters, pediatric: false});
    }
  }, [pediatricContext.isPediatricMode]);


  const customButtons = (
    <Button
      className="btn-sm btn-outline-secondary"
      href="/page/drugLabelLegend"
      newTab={true}
    >
      <InfoIcon />Legend
    </Button>
  );

  const onChange = (e) => {
    if (e.target.checked) {
      setFilters({...filters, [e.target.name]: true});
    } else {
      setFilters({...filters, [e.target.name]: false});
    }
  };

  const customFilters = (
    <div className="labelAnnotationDrugTable__controls form-inline">
      <span className="mb-1">Labels with:</span>
      {createFilter('dosing', DosingInfoTag, filters, onChange, {context: 'labelAnnotation'})}
      {createFilter('altDrug', AlternateDrugTag, filters, onChange, {context: 'labelAnnotation'})}
      {createFilter('otherGuidance', OtherGuidanceTag, filters, onChange, {context: 'labelAnnotation'})}
      {createFilter('prescribing', PrescribingTag, filters, onChange)}
      {createFilter('indication', IndicationTag, filters, onChange)}
      {createFilter('biomarker', BiomarkerTag, filters, onChange)}
      {createFilter('exBiomarker', BiomarkerTag, filters, onChange, {current: false})}
      {createFilter('cancer', CancerTag, filters, onChange)}
      {createFilter('pediatric', PediatricTag, filters, onChange)}
    </div>
  );

  return (
    <div className="labelAnnotationDrugTable">
      <VirtualizedTable
        columns={columns}
        data={rows}
        initialState={initialState}
        updatedFilters={updatedFilters}
        disableExport={false}
        customButtons={customButtons}
        customFilters={customFilters}
      />
    </div>
  );
}
LabelAnnotationDrugTable.propTypes = propTypes;


function filterLabel(filters, label) {
  return (
    (!filters.dosing || label.dosingInfo) &&
    (!filters.altDrug || label.altDrugAvailable) &&
    (!filters.otherGuidance || label.otherPrescribingGuidance) &&
    (!filters.cancer || label.cancerGenome) &&
    (!filters.prescribing || label.prescribing) &&
    (!filters.pediatric || label.pediatric) &&
    (!filters.indication || label.indication) &&
    (!filters.biomarker || isBiomarker(label.biomarkerText)) &&
    (!filters.exBiomarker || isExBiomarker(label.biomarkerText))
  );
}


function renderHeader(name, src, origTable) {
  if (!origTable) {
    return null;
  }

  const {rows, state: {filters}} = origTable;
  let rez = 0;
  if (src === 'drugs') {
    rez = rows.length;
  } else {
    const filteredSource = find(filters, (val) => val.id === src.toLowerCase());
    forEach(rows, ({values}) => {
      if (filteredSource) {
        const {id, value} = filteredSource;
        const filteredValue = filter(values[id], (val) => val?.status === value);
        rez += filteredValue.length;
      } else {
        rez += size(values[src.toLowerCase()]);
      }
    });
  }
  return <>{name} <span className="text-muted">({rez})</span></>;
}

function sourceAccessor(source, filters) {
  return (row) => filter(row?.labels[source], (l) => filterLabel(filters, l));
}

function createFilter(type, Tag, filters, onChange, tagProps) {
  const id = `gaTagFilter-${type}`;
  return (
    <div className="form-check">
      <input
        id={id}
        className="mr-2"
        type="checkbox"
        name={type}
        checked={!!filters[type]}
        onChange={onChange}
      />
      <label htmlFor={id}><Tag className="tag--sm" {...tagProps} /></label>
    </div>
  );
}
