import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import './Tab.scss';
import ClinicalAnnotationsBarChart from 'components/ClinicalAnnotation/BarChart';
import ClinicalAnnotationsTabIntro from 'components/ClinicalAnnotation/TabIntro';
import ClinicalAnnotationTable from 'components/ClinicalAnnotation/Table';
import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import {useTour} from 'components/tour/hook';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  objCls: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default function ClinicalAnnotationsTab({objCls, id}) {
  const appContext = useAppContext();
  const target = objCls === 'combination' ? id : `${objCls}/${id}`;
  const {response, error} = useGet(`site/tab/clinicalAnnotations/${target}`, {
    cacheResponse: !appContext.isPreview,
  });

  const [filter, setFilter] = useState(null);
  
  const readyForTour = useTour('clinicalAnnotationsTabWithoutFocus');
  useEffect(() => {
    if (response) {
      readyForTour();
    }
  }, [readyForTour, response]);

  let body = <Loading />;
  let barChart = <div className="caFigure caFigure--loading figure figure--floatRight"><Loading /></div>;

  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    if (response?.data?.length > 0) {
      body = (
        <ClinicalAnnotationTable
          data={response?.data}
          objCls={objCls}
          updatedFilters={filter}
        />
      );
      barChart = (
        <ClinicalAnnotationsBarChart
          clinicalAnnotations={response?.data}
          filterChangeFn={setFilter}
          captionTitle="Annotations by "
          className="figure--floatRight"
        />
      );
    } else {
      body = <p className="noData">No clinical annotations.</p>;
      barChart = undefined;
    }
  }

  return (
    <div className="clinicalAnnotationsTab">
      <ClinicalAnnotationsTabIntro id={id} chart={barChart} />
      {body}
    </div>
  );
}
ClinicalAnnotationsTab.propTypes = propTypes;
