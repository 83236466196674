import PropTypes from 'prop-types';

import gsiIcon from 'images/icons/gsi.svg';

import AlternateDrugTag from 'components/Tag/AlternateDrug';
import DosingInfoTag from 'components/Tag/DosingInfo';
import OtherGuidanceTag from 'components/Tag/OtherGuidance';
import {FONTAWESOME_FOCUS_ICON} from 'components/icons/Focus';
import DownloadLink from 'components/links/Download';
import Link from 'components/links/Link';
import OutreachTemplate from 'components/templates/Outreach';
import useAppContext from 'conf/AppContext';

const article = {
  type: 'Read',
  text: 'An Evidence-Based Framework for Evaluating Pharmacogenomics Knowledge for Personalized Medicine',
  by: <>Whirl-Carrillo, Huddart <i>et al</i>.</>,
  url: 'https://pubmed.ncbi.nlm.nih.gov/34216021/',
};

const _data = {
  canCollapse: false,
  initialIsCollapsed: false,
  sections: [
    {
      name: 'prescribing',
      title: 'Prescribing Information',
      text: (
        <p>
          Prescribing information includes clinical guidelines for how to adjust prescription of certain medications
          based on a person&apos;s genetic information. Several organizations around the world write these guidelines,
          and PharmGKB provides annotations of them on our website.
        </p>
      ),
      image: 'guidelineAnnotation',
      sections: [
        {
          text: (
            <>
              <p>
                Some organizations provide specific guidelines on how to use a person&apos;s genetic information
                to help select a medication or adjust the dose of a medication.
                PharmGKB provides annotations of clinical guidelines written by
                the <Link newTab={true} href="/page/cpic">Clinical Pharmacogenetic Implementation Consortium (CPIC)</Link>,
                the <Link newTab={true} href="/page/dpwg">Dutch Pharmacogenetics Working Group (DPWG)</Link>, and other
                professional societies including the Canadian Pharmacogenomics Network for Drug Safety (CPNDS) and the
                French National Network of Pharmacogenetics (RNPGx). The CPIC guidelines are created in
                collaboration with PharmGKB.
              </p>
              <p>
                Where applicable, guideline annotations are tagged with the
                {' '}<AlternateDrugTag context="guidelineAnnotation" className="tag--xs" />,
                <DosingInfoTag context="guidelineAnnotation" className="tag--xs" /> or
                <OtherGuidanceTag context="guidelineAnnotation" className="tag--xs" />{' '}
                <Link href="/page/clinicalGuidelineLegend#clinical-guideline-annotation-tags" newTab={true}>tags</Link>{' '}
                to give an overview of the type of recommendation given in the guideline.
                Annotations of CPIC and DPWG guidelines feature an interactive
                {' '}<Link href="page/glossary#genotype" newTab={true}>genotype</Link> picker. Users can enter a
                genotype of interest and see the guideline recommendations specific to that genotype.
              </p>
              <p>
                Some drug labels from regulators (e.g. FDA, EMA, HCSC) also contain pharmacogenomic prescribing
                information. This information is displayed in the Prescribing section of PharmGKB drug label
                annotations and in the Prescribing Info section on pages for drugs, genes or variants. More
                information can be found under the Drug Label Annotations tab on this page.
              </p>
            </>
          ),
        },
        {
          interested: [
            {
              text: 'Walk me through a clinical guideline annotation',
              url: '/guidelineAnnotation/PA166104945?tour=true',
            },
            {
              text: 'See all clinical guideline annotations',
              url: '/guidelineAnnotations',
            },
          ],
        },
      ],
    },
    {
      name: 'annotations',
      title: 'Drug Label Annotations',
      text: (
        <p>
          <Link newTab={true} href="/page/glossary#drug">Drug</Link> label annotations are annotations on medication
          labels that contain <Link newTab={true} href="/page/glossary#pharmacogenomics">pharmacogenomic</Link> (PGx)
          information. PharmGKB currently provides U.S., Canadian, European, Swiss and Japanese medication
          labels with pharmacogenomic information. Drug label annotations are assigned
          a <Link newTab={true} href="/page/drugLabelLegend">level of PGx information</Link> including whether
          they recommend or require testing a patient for a genetic change before giving them the medication.
        </p>
      ),
      image: 'labelAnnotation',
      sections: [
        {
          text: (
            <>
              <p>
                Medication labels for certain drugs contain advice about how to adjust dose based on genetic
                information, or information on the possibility of adverse effects in people with
                certain <Link newTab={true} href="/page/glossary#genetic-variant">genetic variants</Link>, or a
                discussion of the proteins involved in breaking down the drug in the body. The U.S. Food and Drug
                Administration (U.S. FDA) maintains a list of American labels that contain pharmacogenomic information.
                PharmGKB monitors this list and adds these labels to the website, along with a description of
                the specific pharmacogenomic information within the label and a PDF version of the label with
                pharmacogenomic information highlighted. Occasionally PharmGKB finds and annotates labels with
                pharmacogenomic information not on this list.  Labels can also be removed from the U.S. FDA list,
                and in these cases, PharmGKB notes the removal but maintains the annotation for historical purposes.
              </p>
              <p>
                The European Union (European Medicines Agency - EMA) and Canada (Health Canada (Santé Canada) - HCSC) do
                not currently maintain a public list of labels with genetic information, so PharmGKB curators
                use the FDA list to help determine which international labels to search for genetic information.
                Canadian labels come from Health Canada&apos;s website, while European labels come from European
                Public Assessment Reports (EPARs) from the European Medicines Agency (EMA). Swiss labels come from
                Swissmedic and were identified and translated through a text-mining collaboration with the
                Pharmaceutical Care Research Group (PCRG) at the University of Basel. Japanese labels come from
                Pharmaceutical and Medical Devices Agency (PMDA), Japan, and were translated through a
                collaboration with the Japanese Society of Pharmacogenomics.
              </p>
              <p>
                Drug labels annotations can also be tagged, depending on the type of pharmacogenomic information
                contained in the label. For example, the
                {' '}<AlternateDrugTag context="labelAnnotation" className="tag--xs" /> and
                <DosingInfoTag context="labelAnnotation" className="tag--xs" />{' '}
                tags indicate label annotations which contain recommendations that an alternate drug be selected
                or a drug’s dosage be adjusted based on a patient’s genotype. More information on our tags can
                be found <Link href="/page/drugLabelLegend" newTab={true}>here</Link>.
              </p>
            </>
          ),
        },
        {
          interested: [
            {
              text: 'Walk me through a drug label annotation',
              url: '/labelAnnotation/PA166104829?tour=true',
            },
            {
              text: 'See all drug label annotations',
              url: '/labelAnnotations',
            },
            {
              type: 'Read',
              text: 'Blog post on how FDA drug labels are curated',
              by: 'PharmGKB',
              url: 'https://blog.clinpgx.org/fda-approved-drug-labels/',
            },
          ],
        },
      ],
    },
    {
      name: 'gsi',
      title: 'Genotype Selection Interface',
      text: (
        <p>
          PharmGKB’s Genotype Selection Interface (GSI) allows users to select individual genotypes for a number
          of genes then access and compare relevant pharmacogenomic guideline recommendations from
          the <Link href="/page/cpic" newTab={true}>Clinical Pharmacogenetics Implementation Consortium (CPIC)</Link>
          and the <Link href="/page/dpwg" newTab={true}>Dutch Pharmacogenetics Working Group (DPWG)</Link>,
          and wording from the U.S. Food and Drug Administration
          (<Link href="/page/fda" newTab={true}>FDA</Link>)&apos;s approved drug labels and Table of
          Pharmacogenetic Associations
        </p>
      ),
      image: gsiIcon,
      sections: [
        {
          text: (
            <p>
              Visitors to PharmGKB can use the GSI to generate a report of recommendations specific to
              user-provided genotypes for genes with actionable recommendations from CPIC or DPWG.
              If relevant information from FDA-approved drug labels and/or the FDA Table of
              Pharmacogenetic Associations is also available, it is added to the report. The
              {' '}<AlternateDrugTag context="guidelineAnnotation" className="tag--xs" />,
              <DosingInfoTag context="guidelineAnnotation" className="tag--xs" /> and
              <OtherGuidanceTag context="guidelineAnnotation" className="tag--xs" />{' '}tags used on
              {' '}<Link href="/page/clinicalGuidelineLegend#clinical-guideline-annotation-tags" newTab={true}>annotations of clinical guidelines</Link>{' '}
              from CPIC or DPWG and on
              {' '}<Link href="/page/drugLabelLegend#drug-label-annotation-tags" newTab={true}>annotations of FDA approved drug labels</Link>{' '}
              are displayed on the GSI report to give an overview of the types of
              recommendations given by each source.
            </p>
          ),
        },
        {
          interested: [
            {
              text: 'PharmGKB Genotype Selection Interface (GSI)',
              url: '/genotype',
            },
            {
              type: 'Read',
              text: 'More about the GSI',
              by: 'PharmGKB',
              url: '/page/gsiDocumentation',
            },
          ],
        },
      ],
    },
    {
      name: 'pathways',
      title: 'Curated Pathways',
      text: (
        <p>
          Curated pathways are evidence-based diagrams of how a medication is metabolized in your body, how
          a medication works in the body, or both. Pathways diagrams are also accompanied by a written description.
        </p>
      ),
      image: 'pathway',
      sections: [
        {
          text: (
            <>
              <p>
                PharmGKB pathways focus on either the
                {' '}<Link newTab={true} href="/page/glossary#pharmacokinetics">pharmacokinetics</Link> or
                {' '}<Link newTab={true} href="/page/glossary#pharmacodynamics">pharmacodynamics</Link> of
                a <Link newTab={true} href="/page/glossary#drug">drug</Link>, and depict the key candidate pharmacogenes
                that code for the <Link newTab={true} href="/page/glossary#protein">proteins</Link> involved in these processes.
                Most pathways are interactive, allowing users to access more information by clicking on
                the objects in the pathway. In progress pathways provide the preliminary underlying reactions,
                genes, metabolites and evidence in a machine readable format but have not yet been developed
                for publication in a peer-reviewed journal.
              </p>
              <p>
                Connections on a pathway are supported by literature evidence, which can be viewed on the pathway
                page. Pathways are typically created in collaboration with experts in the field.
                They are available for download in multiple formats, including as a PDF file.
                In addition to being provided on the PharmGKB website, pathways are normally
                published in the journal <i>Pharmacogenetics and Genomics</i>.
              </p>
            </>
          ),
        },
        {
          interested: [
            {
              text: 'Walk me through a curated pathway',
              url: '/pathway/PA164713429?tour=true',
            },
            {
              text: 'See all pathways',
              url: '/pathways',
            },
            {
              type: 'Read',
              text: 'PharmGKB as a clinical pharmacogenomic biomarker resource',
              by: <>McDonagh <i>et al</i>.</>,
              url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3339046/',
            },
            {
              type: 'Read',
              text: <i>Blog post on In Progress pathways</i>,
              by: 'PharmGKB',
              url: 'https://pharmgkb.blogspot.com/2021/11/pathways-in-progress-way-to-accelerate.html',
            },
          ],
        },
      ],
    },
    {
      name: 'clinicalAnnotations',
      title: 'Clinical Annotations',
      text: (
        <p>
          Clinical annotations summarize all of PharmGKB&apos;s annotations of published evidence for the relationship
          between a particular <Link newTab={true} href="/page/glossary#genetic-variant"> genetic variant</Link> and a medication.
          They are given a <Link newTab={true} href="/page/clinAnnLevels">Level of Evidence</Link> by PharmGKB depending on how much published
          evidence there is for a relationship found in PharmGKB, and the quality of that evidence.
        </p>
      ),
      image: 'clinicalAnnotation',
      sections: [
        {
          text: (
            <p>
              Clinical annotations are based on variant annotations, and are created by PharmGKB curators by
              bringing together all the variant annotations that discuss the same genetic variant and same
              medication response. Once the curators bring all the variant annotations available in PharmGKB together,
              they write a short summary that describes the findings of these variant annotations.
              The clinical annotation is also given a <Link newTab={true} href="/page/clinAnnScoring">score</Link> based on
              the <Link newTab={true} href="/page/varAnnScoring">score</Link> of all the supporting variant annotations.
              The clinical annotation score is used by PharmGKB curators when assigning a Level of Evidence
              to the annotation. The Level of Evidence takes into account factors such as the number of variant
              annotations showing a positive versus negative result and how many patients were included in studies.
              When a new variant annotation is added to a clinical annotation, the score is recalculated and
              curators re-assess the Level of Evidence, which can increase or decrease based on the results.
            </p>
          ),
        },
        {
          interested: [
            {
              text: 'Walk me through a clinical annotation',
              url: '/clinicalAnnotation/981419260?tour=true',
            },
            {
              type: 'Read',
              text: 'More about Clinical Annotations',
              by: 'PharmGKB',
              url: '/clinicalAnnotations',
            },
            article,
          ],
        },
      ],
    },
    {
      name: 'variantAnnotations',
      title: 'Variant Annotations',
      text: (
        <p>
          Variant annotations are summaries of an association between a single
          {' '}<Link newTab={true} href="/page/glossary#genetic-variant">genetic variant</Link> and a drug response,
          as reported in a single publication. PharmGKB curators add these associations to
          the website when they come across them in published literature.
        </p>
      ),
      image: 'variantAnnotation',
      sections: [
        {
          text: (
            <p>
              Scientists from around the world routinely publish articles showing how a particular genetic
              change affects how individuals respond to a <Link newTab={true} href="/page/glossary#drug">drug</Link>, such as
              whether they have an adverse reaction or respond well. These articles are indexed
              on <Link newTab={true} href="https://www.ncbi.nlm.nih.gov/pubmed/">PubMed</Link>.
              PharmGKB curators use PubMed to find articles that contain
              {' '}<Link newTab={true} href="/page/glossary#pharmacogenomics">pharmacogenomic</Link> information then add that information to PharmGKB. PharmGKB uses
              {' '}<Link newTab={true} href="/page/varAnnScoring">an algorithm</Link> to assess certain features of variant
              annotations and assign a score reflecting the combined attributes of a particular variant
              annotation. Variant annotation scores are used in the calculation of
              {' '}<Link newTab={true} href="/page/clinAnnScoring">clinical annotation scores</Link>.
            </p>
          ),
        },
        {
          interested: [
            {
              text: 'Walk me through a variant annotation',
              url: '/variantAnnotation/1445362018?tour=true',
            },
            {
              type: 'Read',
              text: 'More about variant annotations',
              by: 'PharmGKB',
              url: '/variantAnnotations',
            },
            {
              type: 'Read',
              text: 'PharmGKB as a clinical pharmacogenomic biomarker resource',
              by: <>McDonagh <i>et al</i>.</>,
              url: 'https://pubmed.ncbi.nlm.nih.gov/34216021/',
            },
            article,
          ],
        },
      ],
    },
    {
      name: 'focus',
      title: 'Focus',
      text: (
        <p>
          PharmGKB&apos;s Focus feature allows you to select an area of interest and tailor how you see
          the data on the site. Selecting a Focus changes the display so that annotations relevant
          to that area are pulled out and prominently displayed. Our first Focus area is pediatrics.
        </p>
      ),
      // intentionally using fal to match icons on this page
      image: ['fal', FONTAWESOME_FOCUS_ICON],
      sections: [
        {
          text: (
            <>
              <p>
                The Focus feature can be turned on or off using the switch under the binoculars icon in
                the top right corner of the site. The switch is also prominently displayed on pages with
                relevant annotations. Turning the pediatric Focus on allows annotations which include
                pediatric populations to be brought to the top of the page, making them easier to find.
              </p>
              <p>
                We also offer supplementary content with our pediatric dashboard and pediatric
                summaries for over 200 drugs.
              </p>
            </>
          ),
        },
        {
          interested: [
            {
              text: 'Walk me through the Pediatric Focus',
              url: '/chemical/PA449441/clinicalAnnotation?tour=true',
            },
            {
              type: 'Read',
              text: 'Intro to Focus',
              url: '/page/focusIntro',
            },
            {
              type: 'Read',
              text: 'Pediatric pharmacogenomics',
              url: '/page/PedsIntro',
            },
            {
              text: 'Access the pediatric dashboard',
              url: '/pediatric/dashboard',
            },
          ],
        },
      ],
    },
    {
      name: 'resources',
      title: 'Resources',
      text: (
        <>
          <p>
            The PharmGKB Resources are curated collections of information centered around a chosen topic.
            This can range from pharmacogenomics knowledge relevant to certain clinical areas
            (e.g. cancer, addiction) to lists of internal and external educational resources in
            PGx and pharmacogenomics information from organizations including
            the U.S. Food and Drug Administration (FDA) and the Association for Molecular Pathology (AMP).
            PharmGKB Resources can be found under the Resources heading on the homepage and are listed below:
          </p>
          <ul>
            <li><Link href="/page/cancerPgx" newTab={true}>Cancer Pharmacogenetics</Link> (collection of genes, drugs and pathways with pharmacogenetic information relevant to cancer)</li>
            <li><Link href="/page/DrugUseAndAddiction" newTab={true}>PGx in Drug Use and Addiction</Link> (collection of genes, drugs and pathways with pharmacogenetic information relevant to addiction)</li>
            <li><Link href="/page/COVID" newTab={true}>Therapeutic Resource for COVID-19</Link> (collection of genes, drugs and pathways with pharmacogenetic information relevant to the treatment of COVID-19 and impacts from the COVID-19 pandemic)</li>
            <li><Link href="/page/pgxGeneRef" newTab={true}>Gene Information Tables</Link> (Information tables for genes found in CPIC guidelines. Includes allele definition, functionality and frequency tables)</li>
            <li><Link href="/fdaPgxAssociations" newTab={true}>FDA PGx Associations</Link> (searchable, sortable list of the information contained in the FDA Table of Pharmacogenetic Associations)</li>
            <li><Link href="/prescribingInfo" newTab={true}>Drugs with Prescribing Information</Link> (curated drugs in PharmGKB with prescribing information available from a clinical guideline annotation, annotated drug label or from the FDA Table of Pharmacogenetic Associations)</li>
            <li><Link href="/ampAllelesToTest" newTab={true}>AMP Recommendations</Link> (Table displaying the AMP minimum sets of alleles for pharmacogenomic testing)</li>
            <li><Link href="/page/biogeographicalGroups" newTab={true}>Biogeographical Groups for PGx</Link> (Explanatory information about the PharmGKB biogeographical grouping system)</li>
            <li><Link href="/page/curationTips" newTab={true}>PGx Publication Tips</Link> (advice on how to write scientific publications to ensure they can be easily curated into PharmGKB)</li>
            <li><Link href="/page/educationalResources" newTab={true}>Educational Resources</Link> (Links to educational resources in pharmacogenomics provided by PharmGKB and external organizations)</li>
          </ul>
        </>
      ),
      // intentionally using fal to match icons on this page
      image: ['fal', 'circle-info'],
      sections: [],
    },
    {
      name: 'vips',
      title: 'VIPs',
      text: (
        <p>
          Very Important Pharmacogene (VIP) summaries are overviews
          of <Link newTab={true} href="/page/glossary#gene">genes</Link> that are particularly important in the field
          of <Link newTab={true} href="/page/glossary#pharmacogenomics">pharmacogenomics</Link>.
          They are written for non-experts who want to learn more about a particular pharmacogene.
        </p>
      ),
      image: 'vip',
      sections: [
        {
          text: (
            <p>
              VIPs include background information on the gene such as its structure, the role of
              its <Link newTab={true} href="/page/glossary#protein">protein</Link> and any associations it has with diseases.
              VIP summaries also include in-depth information on particularly important
              {' '}<Link newTab={true} href="/page/glossary#genetic-variant">genetic variants</Link> or
              {' '}<Link newTab={true} href="/page/glossary#haplotype">haplotypes</Link>.
              VIPs are typically written in collaboration with experts on the gene of
              interest. VIPs are normally published in the journal <i>Pharmacogenetics and Genomics</i>,
              in addition to being freely available on the PharmGKB website.
            </p>
          ),
        },
        {
          interested: [
            {
              text: 'Walk me through a VIP',
              url: '/vip/PA166169913?tour=true',
            },
            {
              text: 'See all VIPs',
              url: '/vips',
            },
            {
              type: 'Read',
              text: 'PharmGKB as a clinical pharmacogenomic biomarker resource',
              by: <>McDonagh <i>et al</i>.</>,
              url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3339046/',
            },
          ],
        },
      ],
    },
  ],
};

const propTypes = {
  baseUrl: PropTypes.string.isRequired,
  section: PropTypes.string,
};
export default function WhatIsPharmgkbPage({baseUrl, section}) {
  const appContext = useAppContext();
  return (
    <OutreachTemplate
      title="What is PharmGKB?"
      subtitle={(
        <>
          PharmGKB is an NIH-funded resource that provides information about how human genetic variation affects
          response to medications. PharmGKB collects, curates and disseminates knowledge about clinically actionable
          gene-drug associations and genotype-phenotype relationships.  When using PharmGKB, you will see different
          types of information. These are detailed in the sections below.
        </>
      )}
      alert={(
        <div className="alert alert-info" role="alert">
          <p>
            First time? Consider first taking a <Link newTab={true} href="/?tour=true">short guided clicking tour</Link> through PharmGKB to get
            a feel for the website, and then quickly reading
            about <Link newTab={true} href="/page/searchingPharmgkb">how to search PharmGKB</Link>.
          </p>
          <p>
            PharmGKB also has training exercises available to help new users find their way around the website. Note
            that these exercises are not to be used for credit, including professional development such as CME.
          </p>
          <DownloadLink href={appContext.downloadAttachmentUrl('PharmGKB_Training_Exercises.zip')}>Download training exercises</DownloadLink>
        </div>
      )}
      data={_data}
      section={section}
      baseUrl={baseUrl}
    />
  );
}
WhatIsPharmgkbPage.propTypes = propTypes;
