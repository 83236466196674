import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {map, size} from 'lodash';
import pluralize from 'pluralize';
import {useEffect} from 'react';
import {toast} from 'react-toastify';

import ApproveAndDiffButtons from 'components/Button/ApproveAndDiff';
import DeleteButton from 'components/Button/Delete';
import EditButton from 'components/Button/Edit';
import ClinicalAnnotationEvidenceList from 'components/ClinicalAnnotation/EvidenceList';
import FdaPgxAssociationTable from 'components/FdaPgxAssociation/table';
import HistoryTable from 'components/History/Table';
import PharmgkbTagButton from 'components/PharmgkbTag/Button';
import Section from 'components/Section';
import SimpleTable from 'components/Table/Simple';
import AlternateDrugTag from 'components/Tag/AlternateDrug';
import BiomarkerTag, {isBiomarker, isExBiomarker} from 'components/Tag/Biomarker';
import DosingInfoTag from 'components/Tag/DosingInfo';
import {CancerTag, IndicationTag, PrescribingTag} from 'components/Tag/LabelAnnotation';
import LabelAnnotationLevelTag from 'components/Tag/LabelAnnotationLevel';
import OtherGuidanceTag from 'components/Tag/OtherGuidance';
import PediatricTag from 'components/Tag/Pediatric';
import RetiredTag from 'components/Tag/Retired';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import InfoLink from 'components/links/Info';
import Link from 'components/links/Link';
import {renderResourceLinks} from 'components/links/Resource';
import CuratorOnly from 'components/shared/curator_only';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import HtmlContainer, {addHtmlContainerWhenLoaded} from 'components/shared/html_container';
import TableStylizer from 'components/shared/tableStylizer';
import {useTour} from 'components/tour/hook';
import useAppContext from 'conf/AppContext';
import {DataSource} from 'conf/enums';
import {getBaseUrl} from 'conf/types';
import usePediatricContext from 'context/Pediatric';
import {englishJoin} from 'helpers/util';
import LabelAnnotationSources from 'pages/LabelAnnotation/Sources';


export default function LabelAnnotationDetail({id, data}) {
  const appContext = useAppContext();
  const pediatricContext = usePediatricContext();

  const readyForTour = useTour('labelAnnotation');
  useEffect(() => {
    readyForTour();
  }, [readyForTour]);
  
  const {
    literatureInteractionCounts, fdaPgxAssociations, labelAnnotation, occurrences, approved, published,
  } = data;
  const {
    summaryMarkdown, prescribingMarkdown, textMarkdown, biomarkerStatus, testing, source,
    dosingInformation, alternateDrugAvailable, otherPrescribingGuidance, cancerGenome, literature, history, objCls,
    userId, pediatric, pediatricMarkdown, relatedAlleles, retirementReason, pgxRelated, labelDocumentAvailable,
    indication, relatedGenes,
  } = labelAnnotation;
  
  return (
    <div className="labelAnnotationPage">
      <EditControls>
        <ApproveAndDiffButtons objCls="LabelAnnotation" objId={id} approved={approved} published={published} iconOnly={false} />
        <EditButton href={`/edit/labelAnnotation/${id}`} label="Edit Annotation" />
        {source === 'FDA' && <EditButton href={`/edit/labelAnnotation/${id}/recommendations`} label="Recommendations" disabled={indication} />}
        <DeleteButton
          url={`curation/labelAnnotation/${id}`}
          onSuccess={() => toast.success('Label Annotation Deleted', {autoClose: 5000})}
          onFailure={(labelId, err) => appContext.toastError(<KyError kyError={err} />)}
        />
        <PharmgkbTagButton objId={id} objCls="labelAnnotation" />
        <span className="ml-2 mr-2 fixed-width-font">owner: {userId || <em>unassigned</em>}</span>
      </EditControls>
      <div className="label-detail">
        <FactSection>
          <div className="drug-label-labels">
            {retirementReason && <RetiredTag />}
            {labelDocumentAvailable ? <LabelAnnotationLevelTag level={testing?.term || 'Criteria Not Met'} /> : <p>No label found.</p>}
            <div className="tour-tags">
              {dosingInformation && <DosingInfoTag context="labelAnnotation" />}
              {alternateDrugAvailable && <AlternateDrugTag context="labelAnnotation" />}
              {otherPrescribingGuidance && <OtherGuidanceTag context="labelAnnotation" />}
              {prescribingMarkdown?.html && <PrescribingTag />}
            </div>
            {indication && <IndicationTag />}
            {isBiomarker(biomarkerStatus) && <BiomarkerTag />}
            {isExBiomarker(biomarkerStatus) && <BiomarkerTag current={false} />}
            {cancerGenome && <CancerTag />}
            {pediatric && <PediatricTag />}
          </div>
          <Fact label="PharmGKB ID" literal={id} inline={true} />
        </FactSection>

        {size(relatedAlleles) > 0 && (
          <FactSection title="Variants Discussed on Label">
            {renderResourceLinks(relatedAlleles)}
          </FactSection>
        )}

        {renderSummary(source, summaryMarkdown, pgxRelated, relatedGenes)}

        {!!prescribingMarkdown?.html && (
          <FactSection title="Prescribing" notAvailableWhenEmpty={false} id="prescribing">
            <TableStylizer isInSidebarLayout={false} html={prescribingMarkdown.html} />
          </FactSection>
        )}

        {
          pediatricContext.isPediatricMode && pediatric && pediatricMarkdown && (
            <Section title="Pediatric Excerpts" className="alert alert-info">
              <HtmlContainer html={pediatricMarkdown.html} className="summary-text" />
            </Section>
          )
        }

        {textMarkdown?.html && (
          <FactSection title="Annotation" id="label-annotation">
            <TableStylizer isInSidebarLayout={false} html={textMarkdown.html} />
          </FactSection>
        )}

        {!pgxRelated && (
          <div className="alert alert-warning">
            <FontAwesomeIcon icon={['far', 'triangle-exclamation']} />
            {' '}
            This label does not contain information about PGx.
          </div>
        )}

        {size(fdaPgxAssociations) > 0 && <FdaPgxAssociationTable fdaPgxAssociations={fdaPgxAssociations} />}

        {isExBiomarker(biomarkerStatus) && (
          <FactSection>
            <h3 className="fact-section-header">
              Former FDA Biomarker
            </h3>
            <p>
              This label used to be included on the{' '}
              <Link href="https://www.fda.gov/Drugs/ScienceResearch/ResearchAreas/Pharmacogenetics/ucm083378.htm">
                FDA biomarker list
              </Link>
              {' '}and has since been removed. The PharmGKB curation staff monitors the FDA biomarker list
              on a regular basis and notes removals. There is no official list of removals supplied by
              the FDA.
            </p>
          </FactSection>
        )}

        <FactSection>
          <InfoLink href="/page/drugLabelListingHeader">More information about drug label annotations on PharmGKB</InfoLink>
        </FactSection>

        {pgxRelated && labelDocumentAvailable && <ClinicalAnnotationEvidenceList id={id} objCls={objCls} />}

        {literature.length > 0 && (
          <FactSection title={pluralize('Source', literature.length)}>
            <LabelAnnotationSources
              literature={literature}
              literatureInteractionCounts={literatureInteractionCounts}
            />
          </FactSection>
        )}

        {!labelDocumentAvailable && (
          <div className="alert alert-warning">
            <FontAwesomeIcon icon={['far', 'triangle-exclamation']} />
            {' '}
            The source label document is not available for curation.
          </div>
        )}

        <CuratorOnly>
          {literature.length === 0 && (
            <FactSection title={pluralize('Source', literature.length)}>
              <p>No label sources found.</p>
            </FactSection>
          )}
          <FactSection title="Genes and/or phenotypes found in this label">
            {size(occurrences) > 0
              ? (
                <SimpleTable
                  columns={[
                    {
                      Header: 'Gene/Phenotype',
                      accessor: 'object',
                      // eslint-disable-next-line react/no-unstable-nested-components
                      Cell: (p) => <Link href={getBaseUrl(p.value.id, p.value.objCls)}>{p.value?.symbol || p.value.name} </Link>,
                    },
                    {
                      Header: 'Appears In',
                      accessor: 'SPL',
                      // eslint-disable-next-line react/no-unstable-nested-components
                      Cell: (p) => <ul className="list-unstyled">{map(p.value, (v) => <li key={v}>{v}</li>)}</ul>,
                    },
                    {
                      Header: 'Category',
                      accessor: 'phenoCat',
                      // eslint-disable-next-line react/no-unstable-nested-components
                      Cell: (p) => <ul className="list-unstyled">{map(p.value, (v) => <li key={v}>{v}</li>)}</ul>,
                    },
                    {
                      Header: 'Source',
                      accessor: 'source',
                      // eslint-disable-next-line react/no-unstable-nested-components
                      Cell: (p) => <ul className="list-unstyled">{map(p.value, (v) => <li key={v}>{v}</li>)}</ul>,
                    },
                  ]}
                  data={occurrences}
                />
              )
              : <p><em>No objects specified.</em></p>}
          </FactSection>
        </CuratorOnly>

        <FactSection title="History">
          <HistoryTable history={history} parentCls={objCls} parentId={id} />
        </FactSection>
      </div>
    </div>
  );
}

function renderSummary(source, summaryMarkdown, pgxRelated, relatedGenes) {
  let content;
  if (!pgxRelated) {
    content = 'This label does not have any information to annotate';
    if (relatedGenes?.length !== 0) {
      const geneSymbols = englishJoin(map(relatedGenes, (gene) => gene.symbol), 'or');
      content = `${content} on ${geneSymbols}`;
    }
    content += '.';
  } else if (summaryMarkdown?.html) {
    content = addHtmlContainerWhenLoaded(summaryMarkdown?.html, {className: 'summary-text'});
  } else {
    return null;
  }
  return (
    <FactSection title="Summary" id="label-summary">
      {content}
      {renderBoilerPlateText(source)}
    </FactSection>
  );
}

const collaborationNoteMap = {
  [DataSource.displayName.Swissmedic]:
    'Pharmaceutical Care Research Group in University of Basel in 2019',
  [DataSource.displayName['Pharmaceuticals and Medical Devices Agency, Japan']]:
    'Japanese Society of Pharmacogenomics and Silicon Valley Tech KK in 2016',
};

function renderBoilerPlateText(source) {
  const text = collaborationNoteMap[source];
  if (text) {
    return (
      <p className="mt-3">
        <strong>{`Please note that this drug label annotation was created from a collaboration between PharmGKB and ${text}. The label has not been subsequently reviewed or updated.`}</strong>
      </p>
    );
  } else {
    return null;
  }
}
